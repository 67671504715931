/** FUNCTIONS **/
$textColorLight: white !default;
//$textColorDark: invert($textColorLight) !default;
$textColorDark: #333 !default;

@use "sass:math";
@use "sass:list";

/* Awesome contrast ratio function
* via https://gist.github.com/voxpelli/6304812
**/
@function color_luminance($color) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
  $rgba: red($color), green($color), blue($color);
  $rgba2: ();

  @for $i from 1 through 3 {
    $rgb: nth($rgba, $i);
    $rgb: math.div($rgb, 255);

    $rgb: if($rgb < .03928, math.div($rgb, 12.92), math.pow(math.div(($rgb + .055), 1.055), 2.4));

    $rgba2: append($rgba2, $rgb);
  }

  @return .2126 * list.nth($rgba2, 1) + .7152 * list.nth($rgba2, 2) + 0.0722 * list.nth($rgba2, 3);
}

@function color_contrast($color1, $color2) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
  $luminance1: color_luminance($color1) + .05;
  $luminance2: color_luminance($color2) + .05;
  $ratio: math.div($luminance1, $luminance2);

  @if $luminance2 > $luminance1 {
    $ratio: math.div(1, $ratio);
  }

  $ratio: math.div(round($ratio * 10), 10);

  @return $ratio;
}

/**
 * Determine whether to use dark or light text on top of given color.
 * Returns black for dark text and white for light text.
 */
@function determine-text-color($color, $high: $textColorLight, $low: $textColorDark) {
  @if color_contrast($color, #FFF) < 4.5 {
    @return $high;
  }

  @return $low;
}

@mixin topPanel($name, $topPanelHeight: 900px, $topPanelBackgroundColor: $spot_1, $h1Color: #ffffff, $pColor: #ffffff, $linkColor: #ffffff)
{
  section.topper.#{$name} {
    height: $topPanelHeight;
  }
  section.topper.#{$name} .container-fluid > .row.svg-row {
    //height: $topPanelHeight;
  }
  section.topper.#{$name} .container-fluid > .row.svg-row svg {
    //background: linear-gradient($topPanelBackgroundColor, scale-color($topPanelBackgroundColor, $lightness: -20%));
    //background: linear-gradient($topPanelBackgroundColor, scale-color($topPanelBackgroundColor, $lightness: 40%));
    //background: linear-gradient(scale-color($topPanelBackgroundColor, $lightness: -30%), scale-color($topPanelBackgroundColor, $lightness: 20%));
    background: linear-gradient(scale-color($topPanelBackgroundColor, $lightness: -10%), scale-color($topPanelBackgroundColor, $lightness: 10%));
    height: $topPanelHeight;
  }
  section.topper.#{$name} .container-fluid > .row.content-row {
    margin-top: -$topPanelHeight;
  }
  section.topper.#{$name} .container-fluid > .row.content-row .topper-left h1 {
    color: $h1Color;
    //text-shadow: 1px 1px mix($h1Color, scale-color($topPanelBackgroundColor, $lightness: -80%), 10%);
    font-size: 50px;
    text-rendering: optimizeLegibility;
  }
  section.topper.#{$name} .container-fluid > .row.content-row .topper-left p.shortsum {
    color: $pColor !important;
    //text-shadow: 1px 1px mix($pColor, scale-color($topPanelBackgroundColor, $lightness: -50%), 10%);
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px mix($h1Color, scale-color($topPanelBackgroundColor, $lightness: -80%), 10%);
  }
  section.topper.#{$name} .container-fluid > .row.content-row .topper-left p.shortsum a,
  section.topper.#{$name} .container-fluid > .row.content-row .topper-left p.shortsum a:visited{
    color: $pColor !important;
    //text-shadow: 1px 1px mix($pColor, scale-color($topPanelBackgroundColor, $lightness: -40%), 10%);
    text-decoration: underline;
  }
  section.topper.#{$name} .container-fluid > .row.content-row .topper-right {
    //margin-top: 40px;
    margin-bottom: 40px;
  }
  section.topper.#{$name} .container-fluid > .row.content-row a.btn-link {
    color: $linkColor !important;
  }
  section.topper.#{$name} .container-fluid > .row.content-row a.btn-outline-primary.rounder,
  section.topper.#{$name} .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact4,{
    background-color: scale-color($topPanelBackgroundColor, $lightness: -20%) !important;
    color: $linkColor !important;
    border-color: scale-color($topPanelBackgroundColor, $lightness: -40%) !important;
  }
  section.topper.#{$name} .container-fluid > .row.content-row a.btn-outline-primary.rounder:hover,
  section.topper.#{$name} .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact4:hover{
    background-color: scale-color($topPanelBackgroundColor, $lightness: -30%)!important;
  }

  section.topper.#{$name} .container-fluid > .row.content-row .previews .screenshotx
  {
    box-shadow: 5px 5px 20px scale-color($topPanelBackgroundColor, $lightness: -40%)!important;
  }

}

@mixin keyPoints($name, $h2Color: #fafafa)
{
  .keypoints.#{$name}
  {
    border-top: 1px scale-color($h2Color, $lightness: -20%) solid !important;
    border-left: 1px scale-color($h2Color, $lightness: -20%) solid !important;
    border-right: 1px scale-color($h2Color, $lightness: -40%) solid !important;
    border-bottom: 1px scale-color($h2Color, $lightness: -40%) solid !important;
    margin: 40px !important;
    padding: 4px;
    background: white;
    border-radius: 8px;
    box-shadow: 2px 2px 4px #333;
  }

  .keypoints.#{$name} h2
  {
    color: $h2Color !important;
    text-align: center !important;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.8rem;
  }

  .keypoints.#{$name} ul
  {
    margin-top: 15px !important;
    margin-bottom: 30px !important;
    padding-left: 10px !important;
  }

  .keypoints.#{$name} ul p
  {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }

  .keypoints.#{$name} ul li
  {
    color: $h2Color !important;
    font-size: 1.28em !important;
    line-height: 1.35em !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    list-style-type: none;
  }

  .keypoints.#{$name} ul li p
  {
    padding-top: 10px !important;
    //font-size: 1.0em !important;
    //line-height: 1.0em !important;
    color: #777 !important;
    font-size: 0.85em !important;
    font-weight: 300 !important;
    margin-right: 15px !important;
    line-height: 1.3em !important;
  }

  .keypoints.#{$name} ul li i
  {
    margin-right: 6px !important;
    color: #aaa !important;
  }

  .keypoints.#{$name} ul li i.fa
  {
    padding-right: 8px !important;
  }

}