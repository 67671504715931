/** yellowback button **/

.btn.btn-yellowback {
  color: #1f1f1f !important;
  background-color: #FED86E;
  border-color: #CEB84E;
}

.btn.btn-yellowback:hover {
  color: #2f2f2f;
  background-color: #FFE97F;
  border-color: #DEC85E;
}

.btn.btn-yellowback:focus, .btn.btn-yellowback.focus {
  color: #2f2f2f;
  background-color: #FFE97F;
  border-color: #DEC85E;
  box-shadow: 0 0 0 0.2rem #AE982E;
}

.btn.btn-yellowback.disabled, .btn.btn-yellowback:disabled {
  color: #fff;
  background-color: #FFE97F;
  border-color: #DEC85E;
}

.btn.btn-yellowback:not(:disabled):not(.disabled):active, .btn.btn-yellowback:not(:disabled):not(.disabled).active,
.show > .btn.btn-yellowback.dropdown-toggle {
  color: #2f2f2f;
  background-color: #FFE97F;
  border-color: #DEC85E;
}

.btn.btn-yellowback:not(:disabled):not(.disabled):active:focus, .btn.btn-yellowback:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-yellowback.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #DEC85E;
}





/** green button **/

.btn.btn-greenback {
  color: #fff !important;
  background-color: #28a745;
  border-color: #189735;
}

.btn.btn-greenback:hover {
  color: #fff;
  background-color: #38b755;
  border-color: #28A745;
}

.btn.btn-greenback:focus, .btn.btn-greenback.focus {
  color: #fff;
  background-color: #38b755;
  border-color: #28A745;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn.btn-greenback.disabled, .btn.btn-greenback:disabled {
  color: #fff;
  background-color: #48c765;
  border-color: #38B755;
}

.btn.btn-greenback:not(:disabled):not(.disabled):active, .btn.btn-greenback:not(:disabled):not(.disabled).active,
.show > .btn.btn-greenback.dropdown-toggle {
  color: #fff;
  background-color: #38b755;
  border-color: #28A745;
}

.btn.btn-greenback:not(:disabled):not(.disabled):active:focus, .btn.btn-greenback:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-greenback.dropdown-toggle:focus {
  border-color: #38B755;
}


.btn.submit
{
  background-color: #44bd32 !important;
  border-color: #249d12 !important;
  color: white !important;
  width: 150px;
  border-radius: 8px;
}