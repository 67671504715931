// 1200
// 992
// 768
// 576

@media (max-width: 576px) {

  body{
    //background-color: red !important;
  }

}

.cms-little
{

}

.cms-big
{
  display: none !important;
}

//@media (min-width: 992px)
@media (min-width: 992px)
{
  // hide on any device larger than sm
  .cms-little
  {
    display: none !important;
  }

  .cms-big
  {
    display: flex !important;
  }
}

// little mode
@media (max-width: 992px)
{
  .row.svg-row svg
  {
    visibility: hidden !important;
  }

  section.topper.top-panel-compatible
  {
    height: unset !important;
  }

  section.topper.top-panel-compatible
  {
    background: linear-gradient(#026b94, #16bbfc) !important;
  }

  section.topper .topper-right
  {
    padding-right: 15px !important;
  }

  section.topper .topper-left,
  section.topper .topper-right
  {
    flex: unset !important;
  }

  //div.main.subheader-spaced
  //{
  //  height: 400px;
  //}
  //
  div.main.subheader-spaced {
    margin-top: 270px !important;
  }

  .subheader .subheader-v1-holder
  {
    height: 130px !important;
  }

  .content-section {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .content-section p {
    padding-left: 15px;
  }

  .content-section ul, .content-section ol {
    padding-left: 15px;
    padding-right: 15px;
  }

  header .topmenu nav.navbar ul.navbar-nav>li.nav-item:first-child {
    margin-left: 10px !important;
  }

  .container-fluid.v3-pricing
  {
    margin-top: unset !important;
  }

  .v3-pricing-tables.fit2 .v3-pricing-table {
    min-width: unset !important;
    max-width: unset !important;
  }

  .v3-pricing-featured-highlights
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div.main .container.releases
  {
    margin-top: 20px !important;
  }

  .blogtop
  {
    margin-top: 0px !important;
  }

  .blogside
  {
    display: none !important;
  }
}

.topmenu.cms-little .header-dropdown .navbar-collapse
{
  background-color: white;
  z-index: 999999 !important;
  margin-left: -70px;
}

.topmenu.cms-little .header-dropdown button.navbar-toggler
{
  color: $darktext;
}

.topmenu.cms-little .navbar.snap-holder
{
  justify-content: right;
}

.topmenu.cms-little .navbar.snap-holder .navbar-toggler-icon
{
  font-size: 36px !important;
  width: 1em !important;
  height: 1em !important;
}