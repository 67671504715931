@include topPanel(top-panel-101, 350px, $backdrop_blog);

section.topper.top-panel-101
{
  margin-top: 0px;
  margin-bottom: 20px;
}

section.topper.top-panel-101 .row.svg-row svg
{
  margin-top: -80px;
}

section.topper.top-panel-101 .row.content-row
{
  padding-top: 80px;
}

.blogtop
{
  margin-top: -100px;
}

.blogtop aside.blog-sidebar h4
{
  //color: $backdrop_blog;
  color :#555;
}

.blogtop .component-sidebar h4
{
  color: #555;
}

.blogtop .component-sidebar a.nav-link
{
  color: $backdrop_blog;
}

.blogtop .blog-posts h2.post-title,
.blogtop .blog-posts h2.post-title a
{
  color: $backdrop_blog;
}

.blogtop .blog-posts h2.post-title a:hover
{
  text-underline: none;
}

$blog_text_color: #555 !default;

.blogtop .blog-posts div.post-content,
.blogtop .blog-posts div.post-content h1,
.blogtop .blog-posts div.post-content h2,
.blogtop .blog-posts div.post-content h3,
.blogtop .blog-posts div.post-content h4,
.blogtop .blog-posts div.post-content h5,
.blogtop .blog-posts div.post-content h6,
.blogtop .blog-posts div.post-content p
{
  color: $blog_text_color;
}

.blogtop .blog-posts div.post-content h1,
.blogtop .blog-posts div.post-content h2,
.blogtop .blog-posts div.post-content h3,
.blogtop .blog-posts div.post-content h4,
.blogtop .blog-posts div.post-content h5,
.blogtop .blog-posts div.post-content h6,
{
  margin-top: 20px;
  margin-bottom: 20px;
}

.blogtop .blog-posts div.post-content p
{
  margin-bottom: 20px;
}

.blogtop .blog-posts div.post-content p
{
  font-size: 18px;
}

.blogtop .blog-posts div.post-content img
{
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.blog-header
{

}

.blog-header h2,
.blog-header h2 a
{
  color: white !important;
  font-size: 42px;
}

.blog-header p
{
  font-size: 24px !important;
  color: #eee !important;
  font-weight: 300 !important;
}

.blog-paginator
{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end
}

.blog-paginator .older,
.blog-paginator .newer
{
  margin: 4px;
}


.blog .blog-header > h1
{
  text-align: center !important;
}

.blog .blog-header > h1 > a
{
  color: white;
  text-decoration: none;
}