@include topPanel(top-panel-100, 350px, $backdrop_releases);

section.topper.top-panel-100
{
  margin-top: 0px;
}

.releases h2.release-title a
{
  color: $backdrop-releases;
}

section.releases .row.svg-row svg
{
  margin-top: -80px;
}

section.releases .row.content-row
{
  padding-top: 80px;
}

div.main .container.releases
{
  margin-top: -50px !important;
}

.releases p
{
  color: #555;
  font-size: 19px;
  line-height: 1.35em;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
}

.release-card
{
  border: 0px;
}

.release-notes
{
  border-left: 8px #ddd solid;
  border-top: 1px #ddd solid;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 16px;
  margin: 8px;
}

.release-notes
{
  color: #555;
  font-size: 18px;
  line-height: 1.35em;
  font-weight: 400;
}

.release-notes h1,
.release-notes h1 a,
.release-notes h2,
.release-notes h2 a,
.release-notes h3,
.release-notes h3 a,
.release-notes h4,
.release-notes h4 a,
.release-notes h5,
.release-notes h5 a,
.release-notes h6,
.release-notes h6 a
{
  color: #09639f;
}

.release-buttons
{
  text-align: right;
}

.release-notes h1,
.release-notes h2,
.release-notes h3,
.release-notes h4,
.release-notes h5,
.release-notes h6
{
  margin-top: 20px;
}
