ul.quickstart-steps
{
  text-align: left;
  margin: 3rem 0;
  padding: 1rem;
  background: #F8F8FC;
  list-style-type: none;
  border-radius: 4px;
}

ul.quickstart-steps > li
{
  padding: 1rem;
  border-bottom: 1px solid #e1ebf3;
}

ul.quickstart-steps > li:last-child
{
  border-bottom: 0px;
}

ul.quickstart-steps > li > a
{
  font-size: inherit;
  font-weight: 500;
}

ul.quickstart-steps > li > a > span.step
{
  font-weight: 500;
  display: inline-block;
  background: #5bc0de;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 1rem;
  padding-top: 3px;
}

ul.quickstart-steps > li > a > span.time
{
  display: inline-block;
  float: right;
  font-weight: normal;
}

.step-body
{
  padding-top: 160px;
  margin-top: -120px;
}

.step-body .step
{
  font-weight: 500;
  display: inline-block;
  background: #5bc0de;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  margin-right: 1rem;
  padding-top: 0px;
  font-size: 40px;
}

.step-body h3.media-heading
{
  color: #333;
  margin-top: 14px;
  text-transform: inherit;
}
.step-body ul.timeline h4
{
  color: #444;
  font-size: 1.2em;
  margin-top: -4px;
  font-weight: 400;
}

.step-body a.btn
{
  font-size: 1.0em;
}

.step-body p
{
  margin-bottom: 20px;
  font-weight: 400;
  color: #444;
  font-size: 18.2px;
  line-height: 18.2px;
}

.step-body p.header
{
  font-size: 20px;
  font-weight: bold;
}

.quickstart-image-wrapper
{
  margin: 40px;
  text-align: center;
}

.quickstart-image-wrapper img
{
  max-width: 100%;
  height: auto;
  /*display: block;*/
  text-align: center;
}

@media (min-width: 992px) {

  .quickstart-image-wrapper img.width700
  {
    max-width: 700px;
  }

  .quickstart-image-wrapper img.width600
  {
    max-width: 600px;
  }

  .quickstart-image-wrapper img.width500
  {
    max-width: 500px;
  }

  .quickstart-image-wrapper img.width400
  {
    max-width: 400px;
  }

  .quickstart-image-wrapper img.width300
  {
    max-width: 300px;
  }

}

.quickstart-image-wrapper img.dropshadow
{
  box-shadow: 2px 2px 5px #aaa;
  border: 1px #aaa solid;
  border-radius: 4px;
}

ul.timeline > li ol > li
{
  color: #130000;
  font-size: 18.2px;
  line-height: 18.2px;
  margin-bottom: 40px;
}

ul.timeline > li ul > li
{
  color: #130000;
  font-size: 18.2px;
  line-height: 18.2px;
  margin-bottom: 40px;
}

p.action
{
  font-weight: bold;
}

p.action a
{
  font-weight: bold;
}

p.action i
{
  background-image: url(/images/quickstart/action.png);
  background-size: 48px 48px;
  background-repeat: no-repeat;
  background-position: center;
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 48px;
}

ul.action-steps li
{
  margin-bottom: 10px !important;
}


.btn-copy
{
  margin-left: 10px;
}

ul.timeline > li > p
{
  color: #130000;
  font-size: 18.2px;
  line-height: 18.2px;
  margin-bottom: 40px;
}

.steps.helloworld div.media-body
{
  display:inherit;
  width:inherit;
}

.quickstart-devblocks img
{
  width: 100px;
  height: 100px;
}