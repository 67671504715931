// Palette colors

// https://colorhunt.co/palette/3a98b9fff1dce8d5c4eeeeee

$darktext: #1A3E6F !default;

// https://mycolor.space/?hex=%230968A7&sub=1
// spot palette
$pal: #09639F !default;

$spot_1: $pal !default;
$spot_2: #5895D6 !default;
$spot_3: #DBF2FF !default;
$spot_4: #DEA01E !default;

// twisted spot
$twist_1: $pal !default;
$twist_2: #DEA01E !default;
$twist_3: #FFEDCB !default;
$twist_4: #897456 !default;

// natural
$natural_3: #F4FAFF !default;

// gray back
$grayback1: #f5f5f5;

$whiteback1: #ffffff;

// my own
$yellowback-color: #fed86e !default;

// check
$check_color: #099F63 !default;

//$btn-outline-primary: #09639f !default;
$btn-outline-primary: #2983Af !default;

//$backdrop_home: $pal !default;
//$backdrop_home: #2983Af !default;
//$backdrop_home: #00b4d8 !default;
//$backdrop_home: #3A98B9 !default;
//$backdrop_home: #2879Af !default;
//$backdrop_home: #039BE5 !default;
//$backdrop_home: #2980B9 !default;
//$backdrop_home: #0399E4 !default;
//$backdrop_home: #0379B4 !default;
//$backdrop_home: #0399D4 !default;
//$backdrop_home: #249EDC !default;
$backdrop_home: #227093 !default;
$backdrop_releases: #2983Af !default;
$backdrop_blog: scale-color(#0088df, $lightness: -20%) !default !default;
$backdrop_pricing: #2983Af !default;

//$backdrop_platform_overview: #0088df !default;
//$backdrop_platform_curate: #639F09 !default;
//$backdrop_platform_curate: #0088df !default;
//$backdrop_platform_cloudcms: #2983Af !default;
//$backdrop_platform_cloudcms: #1984c5 !default;
//$backdrop_platform_engine: #897456 !default;
//$backdrop_platform_engine: scale-color(#2f3640, $lightness: 10%) !default;
//$backdrop_platform_engine: #2980B9 !default;

//$backdrop_platform_cloudcms: #0096c7 !default;
//$backdrop_platform_engine: mix(#0096c7, #38bad4, 80%) !default;
//$backdrop_platform_pipeline: mix(#0096c7, #38bad4, 60%) !default;
//$backdrop_platform_automation: mix(#0096c7, #38bad4, 40%) !default;
//$backdrop_platform_train: mix(#0096c7, #38bad4, 20%) !default;
//$backdrop_platform_applications: #38bad4 !default;

//$backdrop_platform_cloudcms: #1A7899 !default;
//$backdrop_platform_engine: mix(#5AB8D9, #1A7899, 20%) !default;
//$backdrop_platform_pipeline: mix(#5AB8D9, #1A7899, 40%) !default;
//$backdrop_platform_automation: mix(#5AB8D9, #1A7899, 60%) !default;
//$backdrop_platform_train: mix(#5AB8D9, #1A7899, 80%) !default;
//$backdrop_platform_applications: #5AB8D9 !default;

//$backdrop_platform_cloudcms: #03A9F4 !default;
//$backdrop_platform_engine: #039BE5 !default;
//$backdrop_platform_pipeline: #0288D1 !default;
//$backdrop_platform_automation: #03A9F4 !default;
//$backdrop_platform_train: #039BE5 !default;
//$backdrop_platform_applications: #0288D1 !default;

//#0379B4
$backdrop_platform_cloudcms: #0399D4 !default;
$backdrop_platform_engine: #0399D4 !default;
$backdrop_platform_automation: #0399D4 !default;
$backdrop_platform_pipeline: #0399D4 !default;
$backdrop_platform_train: #0399D4 !default;
$backdrop_platform_applications: #0399D4 !default; // 700



//$backdrop_usecase_digital_experiences: #339C9C !default;
//$backdrop_usecase_education: #117CCC !default;
//$backdrop_usecase_finance: #338C33 !default;
//$backdrop_usecase_healthcare: #CA5380 !default;
//$backdrop_usecase_insurance: #567489 !default;
//$backdrop_usecase_travel: #BE8000 !default;

//$backdrop_usecase_digital_experiences: #5c80bc !default; //
//$backdrop_usecase_ai: #43aa8b !default;
//$backdrop_usecase_healthcare: #00abe9 !default; //
//$backdrop_usecase_travel: #e36414 !default;

//$backdrop_usecase_healthcare: #00BCD4 !default; //
//$backdrop_usecase_ai: #0097A7 !default;
//$backdrop_usecase_digital_experiences: #00BCD4 !default; //
//$backdrop_usecase_travel: #0097A7 !default;

//$backdrop_usecase_healthcare: #0097A7 !default; //
//$backdrop_usecase_ai: #00796B !default;
//$backdrop_usecase_digital_experiences: #0097A7 !default; //
//$backdrop_usecase_travel: #00796B !default;

$backdrop_usecase_healthcare: #00BCD4 !default; //
$backdrop_usecase_ai: #F4511E !default;
$backdrop_usecase_digital_experiences: #4CAF50 !default; //
$backdrop_usecase_travel: #4FC3F7 !default;

$backdrop_landing_40: #757575 !default;
$backdrop_landing_ai: #0399D4 !default;


//$backdrop_platform_overview: #0088df !default;
//$backdrop_platform_curate: #df88df !default;


//$backdrop_solution_document_management: #00a8ff !default;
//$backdrop_solution_web_content_management: #44bd32 !default;
//$backdrop_solution_records_management: #2983Af !default;
//$backdrop_solution_digital_asset_management: #c23616 !default;
//$backdrop_solution_taxonomy: #897456 !default;



//$backdrop_features_editors: $twist_4 !default;
////$backdrop_features_publishing: #44bd32 !default;
//$backdrop_features_publishing: #895674 !default;
////$backdrop_features_marketing: #c23616 !default;
//$backdrop_features_marketing: #568974 !default;
////$backdrop_features_developers: #7f8fa6 !default;
////$backdrop_features_developers: scale-color(#7f8fa6, $lightness: -60%);
////$backdrop_features_developers: #9c88ff !default;
//$backdrop_features_developers: scale-color(#0088df, $lightness: -20%) !default;
//$backdrop_features_enterprise: scale-color(#2f3640, $lightness: 10%) !default;
////
