// mixins
@import "mixins.scss";

// acquired palette
@import "palette.scss";

// core bootstrap
@import "variables.scss";

// global
@import "global.scss";

// components
@import "topbar.scss";
@import "buttons.scss";
@import "status-icon.scss";

// pages
@import "home.scss";
@import "documentation.scss";
@import "pricing.scss";
@import "trial.scss";

@import "platform.scss";
@import "usecases.scss";
@import "landing.scss";

@import "releases.scss";
@import "blog.scss";

@import "quickstart.scss";

//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body
{
  // fun
  //font-family: multi-display, sans-serif;

  //font-family: proxima-nova, sans-serif;
  //font-style: normal;

  //font-family: Akzidenz Grotesk BQ Medium,Helvetica,Arial !important;

  //font-family: "lato", sans-serif;
  //font-weight: 400;
  //font-style: normal;

  font-family: "urw-form", sans-serif;
  //font-family: "urw-form-semi-condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

//body .nav-link
//{
//  font-family: Akzidenz-Grotesk Std;
//}

@import "compatibility.scss";

body
{
  //display: none;
}