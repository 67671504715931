/* jsTree default theme */
.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}
.jstree-node {
  white-space: nowrap;
}
.jstree-anchor {
  display: inline-block;
  color: #333;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  margin: 0;
  vertical-align: top;
}
.jstree-anchor:focus {
  outline: 0;
}
.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
  text-decoration: none;
  color: inherit;
}
.jstree-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.jstree-icon:empty {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.jstree-ocl {
  cursor: pointer;
}
.jstree-leaf > .jstree-ocl {
  cursor: default;
}
.jstree .jstree-open > .jstree-children {
  display: block;
}
.jstree .jstree-closed > .jstree-children,
.jstree .jstree-leaf > .jstree-children {
  display: none;
}
.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}
.jstree-no-icons .jstree-themeicon,
.jstree-anchor > .jstree-themeicon-hidden {
  display: none;
}
.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}
.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}
.jstree-rtl .jstree-node {
  margin-left: 0;
}
.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}
.jstree-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;
}
.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}
.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
  position: relative;
}
.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vakata-context {
  display: none;
}
.vakata-context,
.vakata-context ul {
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  -moz-box-shadow: 5px 5px 4px -4px #666666;
  -webkit-box-shadow: 2px 2px 2px #999999;
  box-shadow: 2px 2px 2px #999999;
}
.vakata-context ul {
  list-style: none;
  left: 100%;
  margin-top: -2.7em;
  margin-left: -4px;
}
.vakata-context .vakata-context-right ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context li {
  list-style: none;
  display: inline;
}
.vakata-context li > a {
  display: block;
  padding: 0 2em 0 2em;
  text-decoration: none;
  width: auto;
  color: black;
  white-space: nowrap;
  line-height: 2.4em;
  -moz-text-shadow: 1px 1px 0 white;
  -webkit-text-shadow: 1px 1px 0 white;
  text-shadow: 1px 1px 0 white;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}
.vakata-context li > a:hover {
  position: relative;
  background-color: #e8eff7;
  -moz-box-shadow: 0 0 2px #0a6aa1;
  -webkit-box-shadow: 0 0 2px #0a6aa1;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: right center;
  background-repeat: no-repeat;
}
.vakata-context li > a:focus {
  outline: 0;
}
.vakata-context .vakata-context-hover > a {
  position: relative;
  background-color: #e8eff7;
  -moz-box-shadow: 0 0 2px #0a6aa1;
  -webkit-box-shadow: 0 0 2px #0a6aa1;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context .vakata-context-separator > a,
.vakata-context .vakata-context-separator > a:hover {
  background: white;
  border: 0;
  border-top: 1px solid #e2e3e3;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  padding: 0;
  margin: 0 0 0 2.4em;
  border-left: 1px solid #e0e0e0;
  -moz-text-shadow: 0 0 0 transparent;
  -webkit-text-shadow: 0 0 0 transparent;
  text-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0;
}
.vakata-context li > a > i {
  text-decoration: none;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  background: transparent;
  margin: 0 0 0 -2em;
  vertical-align: top;
  text-align: center;
  line-height: 2.4em;
}
.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}
.vakata-context li > a .vakata-contextmenu-sep {
  display: inline-block;
  width: 1px;
  height: 2.4em;
  background: white;
  margin: 0 0.5em 0 0;
  border-left: 1px solid #e2e3e3;
}
.vakata-context .vakata-contextmenu-shortcut {
  font-size: 0.8em;
  color: silver;
  opacity: 0.5;
  display: none;
}
.vakata-context-rtl ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: left center;
  background-repeat: no-repeat;
}
.vakata-context-rtl .vakata-context-separator > a {
  margin: 0 2.4em 0 0;
  border-left: 0;
  border-right: 1px solid #e2e3e3;
}
.vakata-context-rtl .vakata-context-left ul {
  right: auto;
  left: 100%;
  margin-left: -4px;
  margin-right: auto;
}
.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}
.vakata-context-rtl li > a .vakata-contextmenu-sep {
  margin: 0 0 0 0.5em;
  border-left-color: white;
  background: #e2e3e3;
}
#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;
}
#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px;
}
#jstree-dnd .jstree-ok {
  background: green;
}
#jstree-dnd .jstree-er {
  background: red;
}
#jstree-dnd .jstree-copy {
  margin: 0 2px 0 2px;
}
.jstree-proton .jstree-node,
.jstree-proton .jstree-icon {
  background-repeat: no-repeat;
  background-color: transparent;
}
.jstree-proton .jstree-anchor,
.jstree-proton .jstree-wholerow {
  transition: background-color 0.15s, box-shadow 0.15s, color 0.15s;
}
.jstree-proton .jstree-hovered {
  background: #76b6ec;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #76b6ec;
}
.jstree-proton .jstree-clicked {
  background: #3392e3;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #3392e3;
}
.jstree-proton .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}
.jstree-proton .jstree-disabled {
  background: transparent;
  color: #666666;
}
.jstree-proton .jstree-disabled.jstree-hovered {
  background: transparent;
  box-shadow: none;
}
.jstree-proton .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-proton .jstree-disabled > .jstree-icon {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ *
filter: gray;
/* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.jstree-proton .jstree-search {
  font-style: italic;
  color: #8b0000;
  font-weight: bold;
}
.jstree-proton .jstree-no-checkboxes .jstree-checkbox {
  display: none !important;
}
.jstree-proton.jstree-checkbox-no-clicked .jstree-clicked {
  background: transparent;
  color: inherit;
  box-shadow: none;
}
.jstree-proton.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background: #76b6ec;
  color: #ffffff;
}
.jstree-proton.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked {
  background: transparent;
  color: inherit;
}
.jstree-proton.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: #76b6ec;
  color: #ffffff;
}
.jstree-proton > .jstree-striped {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat;
}
.jstree-proton > .jstree-wholerow-ul .jstree-hovered,
.jstree-proton > .jstree-wholerow-ul .jstree-clicked {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.jstree-proton .jstree-wholerow {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.jstree-proton .jstree-wholerow-hovered {
  background: #76b6ec;
}
.jstree-proton .jstree-wholerow-clicked {
  background: #3392e3;
  background: -moz-linear-gradient(top, #3392e3 0%, #3392e3 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3392e3), color-stop(100%, #3392e3));
  background: -webkit-linear-gradient(top, #3392e3 0%, #3392e3 100%);
  background: -o-linear-gradient(top, #3392e3 0%, #3392e3 100%);
  background: -ms-linear-gradient(top, #3392e3 0%, #3392e3 100%);
  background: linear-gradient(to bottom, #3392e3 0%, #3392e3 100%);
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color1', endColorstr='@color2',GradientType=0 );*/
}
.jstree-proton .jstree-node {
  min-height: 22px;
  line-height: 22px;
  margin-left: 22px;
  min-width: 22px;
}
.jstree-proton .jstree-anchor {
  line-height: 22px;
  margin: 1px 0 2px;
  height: 22px;
}
.jstree-proton .jstree-icon {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.jstree-proton .jstree-icon:empty {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.jstree-proton.jstree-rtl .jstree-node {
  margin-right: 22px;
}
.jstree-proton .jstree-wholerow {
  height: 22px;
}
.jstree-proton .jstree-node,
.jstree-proton .jstree-icon {
  background-size: 320px 96px;
  background-image: url("32px.png");
}
.jstree-proton .jstree-node {
  background-position: -293px -5px;
  background-repeat: repeat-y;
}
.jstree-proton .jstree-last {
  background: transparent;
}
.jstree-proton .jstree-open > .jstree-ocl {
  background-position: -133px -5px;
}
.jstree-proton .jstree-closed > .jstree-ocl {
  background-position: -101px -5px;
}
.jstree-proton .jstree-leaf > .jstree-ocl {
  background-position: -69px -5px;
}
.jstree-proton .jstree-themeicon {
  background-position: -261px -7px;
}
.jstree-proton > .jstree-no-dots .jstree-node,
.jstree-proton > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -37px -5px;
}
.jstree-proton > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -5px -5px;
}
.jstree-proton .jstree-disabled {
  background: transparent;
}
.jstree-proton .jstree-disabled.jstree-hovered {
  background: transparent;
}
.jstree-proton .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-proton .jstree-checkbox {
  background-position: -165px -5px;
}
.jstree-proton .jstree-checkbox:hover {
  background-position: -165px -37px;
}
.jstree-proton.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-proton .jstree-checked > .jstree-checkbox {
  background-position: -229px -5px;
}
.jstree-proton.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-proton .jstree-checked > .jstree-checkbox:hover {
  background-position: -229px -37px;
}
.jstree-proton .jstree-anchor > .jstree-undetermined {
  background-position: -197px -5px;
}
.jstree-proton .jstree-anchor > .jstree-undetermined:hover {
  background-position: -197px -37px;
}
.jstree-proton > .jstree-striped {
  background-size: auto 44px;
}
.jstree-proton.jstree-rtl .jstree-node {
  background-size: 320px 96px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.jstree-proton.jstree-rtl .jstree-last {
  background: transparent;
}
.jstree-proton.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -133px -37px;
}
.jstree-proton.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -101px -37px;
}
.jstree-proton.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -69px -37px;
}
.jstree-proton.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-proton.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -37px -37px;
}
.jstree-proton.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -5px -37px;
}
.jstree-proton .jstree-themeicon-custom {
  background-color: transparent;
  background-size: 320px 96px;
  background-image: none;
  background-position: 0 0;
}
.jstree-proton > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.gif") center center no-repeat;
}
.jstree-proton .jstree-file {
  background: url("32px.png") -101px -69px no-repeat;
}
.jstree-proton .jstree-folder {
  background: url("32px.png") -261px -5px no-repeat;
}
.jstree-proton > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}
#jstree-dnd.jstree-proton {
  line-height: 22px;
  padding: 0 4px;
}
#jstree-dnd.jstree-proton .jstree-ok,
#jstree-dnd.jstree-proton .jstree-er {
  background-size: 320px 96px;
  background-image: url("32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#jstree-dnd.jstree-proton i {
  background: transparent;
  width: 22px;
  height: 22px;
  line-height: 22px;
}
#jstree-dnd.jstree-proton .jstree-ok {
  background-position: -5px -69px;
}
#jstree-dnd.jstree-proton .jstree-er {
  background-position: -37px -69px;
}
.jstree-proton.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}
.jstree-proton.jstree-rtl .jstree-last {
  background: transparent;
}
.jstree-proton-small .jstree-node {
  min-height: 18px;
  line-height: 18px;
  margin-left: 18px;
  min-width: 18px;
}
.jstree-proton-small .jstree-anchor {
  line-height: 18px;
  margin: 1px 0 2px;
  height: 18px;
}
.jstree-proton-small .jstree-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.jstree-proton-small .jstree-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.jstree-proton-small.jstree-rtl .jstree-node {
  margin-right: 18px;
}
.jstree-proton-small .jstree-wholerow {
  height: 18px;
}
.jstree-proton-small .jstree-node,
.jstree-proton-small .jstree-icon {
  background-size: 320px 96px;
  background-image: url("32px.png");
}
.jstree-proton-small .jstree-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}
.jstree-proton-small .jstree-last {
  background: transparent;
}
.jstree-proton-small .jstree-open > .jstree-ocl {
  background-position: -135px -7px;
}
.jstree-proton-small .jstree-closed > .jstree-ocl {
  background-position: -103px -7px;
}
.jstree-proton-small .jstree-leaf > .jstree-ocl {
  background-position: -71px -7px;
}
.jstree-proton-small .jstree-themeicon {
  background-position: -263px -9px;
}
.jstree-proton-small > .jstree-no-dots .jstree-node,
.jstree-proton-small > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton-small > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -7px;
}
.jstree-proton-small > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -7px;
}
.jstree-proton-small .jstree-disabled {
  background: transparent;
}
.jstree-proton-small .jstree-disabled.jstree-hovered {
  background: transparent;
}
.jstree-proton-small .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-proton-small .jstree-checkbox {
  background-position: -167px -7px;
}
.jstree-proton-small .jstree-checkbox:hover {
  background-position: -167px -39px;
}
.jstree-proton-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-proton-small .jstree-checked > .jstree-checkbox {
  background-position: -231px -7px;
}
.jstree-proton-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-proton-small .jstree-checked > .jstree-checkbox:hover {
  background-position: -231px -39px;
}
.jstree-proton-small .jstree-anchor > .jstree-undetermined {
  background-position: -199px -7px;
}
.jstree-proton-small .jstree-anchor > .jstree-undetermined:hover {
  background-position: -199px -39px;
}
.jstree-proton-small > .jstree-striped {
  background-size: auto 36px;
}
.jstree-proton-small.jstree-rtl .jstree-node {
  background-size: 320px 96px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.jstree-proton-small.jstree-rtl .jstree-last {
  background: transparent;
}
.jstree-proton-small.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -135px -39px;
}
.jstree-proton-small.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -103px -39px;
}
.jstree-proton-small.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -71px -39px;
}
.jstree-proton-small.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-proton-small.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton-small.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -39px;
}
.jstree-proton-small.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -39px;
}
.jstree-proton-small .jstree-themeicon-custom {
  background-color: transparent;
  background-size: 320px 96px;
  background-image: none;
  background-position: 0 0;
}
.jstree-proton-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.gif") center center no-repeat;
}
.jstree-proton-small .jstree-file {
  background: url("32px.png") -103px -71px no-repeat;
}
.jstree-proton-small .jstree-folder {
  background: url("32px.png") -263px -7px no-repeat;
}
.jstree-proton-small > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}
#jstree-dnd.jstree-proton-small {
  line-height: 18px;
  padding: 0 4px;
}
#jstree-dnd.jstree-proton-small .jstree-ok,
#jstree-dnd.jstree-proton-small .jstree-er {
  background-size: 320px 96px;
  background-image: url("32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#jstree-dnd.jstree-proton-small i {
  background: transparent;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
#jstree-dnd.jstree-proton-small .jstree-ok {
  background-position: -7px -71px;
}
#jstree-dnd.jstree-proton-small .jstree-er {
  background-position: -39px -71px;
}
.jstree-proton-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}
.jstree-proton-small.jstree-rtl .jstree-last {
  background: transparent;
}
.jstree-proton-large .jstree-node {
  min-height: 32px;
  line-height: 32px;
  margin-left: 32px;
  min-width: 32px;
}
.jstree-proton-large .jstree-anchor {
  line-height: 32px;
  margin: 1px 0 2px;
  height: 32px;
}
.jstree-proton-large .jstree-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.jstree-proton-large .jstree-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.jstree-proton-large.jstree-rtl .jstree-node {
  margin-right: 32px;
}
.jstree-proton-large .jstree-wholerow {
  height: 32px;
}
.jstree-proton-large .jstree-node,
.jstree-proton-large .jstree-icon {
  background-size: 320px 96px;
  background-image: url("32px.png");
}
.jstree-proton-large .jstree-node {
  background-position: -288px 0px;
  background-repeat: repeat-y;
}
.jstree-proton-large .jstree-last {
  background: transparent;
}
.jstree-proton-large .jstree-open > .jstree-ocl {
  background-position: -128px 0px;
}
.jstree-proton-large .jstree-closed > .jstree-ocl {
  background-position: -96px 0px;
}
.jstree-proton-large .jstree-leaf > .jstree-ocl {
  background-position: -64px 0px;
}
.jstree-proton-large .jstree-themeicon {
  background-position: -256px -2px;
}
.jstree-proton-large > .jstree-no-dots .jstree-node,
.jstree-proton-large > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton-large > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px 0px;
}
.jstree-proton-large > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0px 0px;
}
.jstree-proton-large .jstree-disabled {
  background: transparent;
}
.jstree-proton-large .jstree-disabled.jstree-hovered {
  background: transparent;
}
.jstree-proton-large .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-proton-large .jstree-checkbox {
  background-position: -160px 0px;
}
.jstree-proton-large .jstree-checkbox:hover {
  background-position: -160px -32px;
}
.jstree-proton-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-proton-large .jstree-checked > .jstree-checkbox {
  background-position: -224px 0px;
}
.jstree-proton-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-proton-large .jstree-checked > .jstree-checkbox:hover {
  background-position: -224px -32px;
}
.jstree-proton-large .jstree-anchor > .jstree-undetermined {
  background-position: -192px 0px;
}
.jstree-proton-large .jstree-anchor > .jstree-undetermined:hover {
  background-position: -192px -32px;
}
.jstree-proton-large > .jstree-striped {
  background-size: auto 64px;
}
.jstree-proton-large.jstree-rtl .jstree-node {
  background-size: 320px 96px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}
.jstree-proton-large.jstree-rtl .jstree-last {
  background: transparent;
}
.jstree-proton-large.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -128px -32px;
}
.jstree-proton-large.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -96px -32px;
}
.jstree-proton-large.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -64px -32px;
}
.jstree-proton-large.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-proton-large.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-proton-large.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px -32px;
}
.jstree-proton-large.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0px -32px;
}
.jstree-proton-large .jstree-themeicon-custom {
  background-color: transparent;
  background-size: 320px 96px;
  background-image: none;
  background-position: 0 0;
}
.jstree-proton-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.gif") center center no-repeat;
}
.jstree-proton-large .jstree-file {
  background: url("32px.png") -96px -64px no-repeat;
}
.jstree-proton-large .jstree-folder {
  background: url("32px.png") -256px 0px no-repeat;
}
.jstree-proton-large > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}
#jstree-dnd.jstree-proton-large {
  line-height: 32px;
  padding: 0 4px;
}
#jstree-dnd.jstree-proton-large .jstree-ok,
#jstree-dnd.jstree-proton-large .jstree-er {
  background-size: 320px 96px;
  background-image: url("32px.png");
  background-repeat: no-repeat;
  background-color: transparent;
}
#jstree-dnd.jstree-proton-large i {
  background: transparent;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#jstree-dnd.jstree-proton-large .jstree-ok {
  background-position: 0px -64px;
}
#jstree-dnd.jstree-proton-large .jstree-er {
  background-position: -32px -64px;
}
.jstree-proton-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}
.jstree-proton-large.jstree-rtl .jstree-last {
  background: transparent;
}
@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    line-height: 30px;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }
  #jstree-dnd.jstree-dnd-responsive > i {
    background: transparent;
    width: 30px;
    height: 30px;
  }
  #jstree-dnd.jstree-dnd-responsive > .jstree-ok {
    background-image: url("30px.png");
    background-position: 0 -150px;
    background-size: 90px 180px;
  }
  #jstree-dnd.jstree-dnd-responsive > .jstree-er {
    background-image: url("30px.png");
    background-position: -30px -150px;
    background-size: 90px 180px;
  }
  #jstree-marker.jstree-dnd-responsive {
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    margin-top: -10px;
  }
}
@media (max-width: 768px) {
  .jstree-proton-responsive {
    /*
	.jstree-open > .jstree-ocl,
	.jstree-closed > .jstree-ocl {
	border-radius:20px;
	background-color:white;
	}
	*/
  }
  .jstree-proton-responsive .jstree-container-ul {
    overflow: hidden;
  }
  .jstree-proton-responsive .jstree-icon {
    background-image: url("30px.png");
    background-size: 90px 180px;
  }
  .jstree-proton-responsive .jstree-node,
  .jstree-proton-responsive .jstree-leaf > .jstree-ocl {
    background: transparent;
  }
  .jstree-proton-responsive .jstree-node {
    min-height: 30px;
    line-height: 30px;
    margin-left: 30px;
    min-width: 30px;
    white-space: nowrap;
  }
  .jstree-proton-responsive .jstree-anchor {
    line-height: 38px;
    height: 38px;
    margin: 0;
  }
  .jstree-proton-responsive .jstree-icon,
  .jstree-proton-responsive .jstree-icon:empty {
    margin-top: 4px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .jstree-proton-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
  }
  .jstree-proton-responsive.jstree-rtl .jstree-node {
    margin-left: 0;
    margin-right: 30px;
  }
  .jstree-proton-responsive.jstree-rtl .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }
  .jstree-proton-responsive .jstree-ocl,
  .jstree-proton-responsive .jstree-themeicon,
  .jstree-proton-responsive .jstree-checkbox {
    background-size: 90px 180px;
  }
  .jstree-proton-responsive .jstree-leaf > .jstree-ocl {
    background: transparent;
  }
  .jstree-proton-responsive .jstree-open > .jstree-ocl {
    background-position: 0 0px !important;
  }
  .jstree-proton-responsive .jstree-closed > .jstree-ocl {
    background-position: 0 -30px !important;
  }
  .jstree-proton-responsive.jstree-rtl .jstree-closed > .jstree-ocl {
    background-position: -30px 0px !important;
  }
  .jstree-proton-responsive .jstree-themeicon {
    background-position: -30px -30px;
  }
  .jstree-proton-responsive .jstree-checkbox,
  .jstree-proton-responsive .jstree-checkbox:hover {
    background-position: -30px -60px;
  }
  .jstree-proton-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
  .jstree-proton-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
  .jstree-proton-responsive .jstree-checked > .jstree-checkbox,
  .jstree-proton-responsive .jstree-checked > .jstree-checkbox:hover {
    background-position: 0 -60px;
  }
  .jstree-proton-responsive .jstree-anchor > .jstree-undetermined,
  .jstree-proton-responsive .jstree-anchor > .jstree-undetermined:hover {
    background-position: 0 -90px;
  }
  .jstree-proton-responsive > .jstree-striped {
    background: transparent;
  }
  .jstree-proton-responsive .jstree-wholerow-ul li {
    position: relative;
  }
  .jstree-proton-responsive .jstree-wholerow-ul .jstree-wholerow {
    position: absolute;
    top: 0;
    left: -1000px;
    right: -1000px;
    width: auto;
    height: 100%;
  }
  .jstree-proton-responsive .jstree-wholerow {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background: rgba(0, 0, 0, 0.02);
    height: 30px;
  }
  .jstree-proton-responsive .jstree-wholerow-hovered {
    background: #76b6ec;
  }
  .jstree-proton-responsive .jstree-wholerow-clicked {
    background: #3392e3;
  }
  .jstree-proton-responsive .jstree-children .jstree-open + .jstree-open {
    box-shadow: none;
  }
  .jstree-proton-responsive .jstree-node,
  .jstree-proton-responsive .jstree-icon,
  .jstree-proton-responsive .jstree-node > .jstree-ocl,
  .jstree-proton-responsive .jstree-themeicon,
  .jstree-proton-responsive .jstree-checkbox {
    background-image: url("30px.png");
    background-size: 90px 180px;
  }
  .jstree-proton-responsive .jstree-node {
    background-position: -60px 0;
    background-repeat: repeat-y;
  }
  .jstree-proton-responsive .jstree-last {
    background: transparent;
  }
  .jstree-proton-responsive .jstree-leaf > .jstree-ocl {
    background-position: -30px -90px;
  }
  .jstree-proton-responsive .jstree-last > .jstree-ocl {
    background-position: -30px -120px;
  }
  .jstree-proton-responsive .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }
  .jstree-proton-responsive .jstree-file {
    background: url("30px.png") 0 -120px no-repeat;
    background-size: 90px 180px;
  }
  .jstree-proton-responsive .jstree-folder {
    background: url("30px.png") -30px -30px no-repeat;
    background-size: 90px 180px;
  }
  .jstree-proton-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/titillium/titilliumweb-extralight-webfont.eot');
  src: url('fonts/titillium/titilliumweb-extralight-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/titillium/titilliumweb-extralight-webfont.woff') format('woff'), url('fonts/titillium/titilliumweb-extralight-webfont.ttf') format('truetype'), url('fonts/titillium/titilliumweb-extralight-webfont.svg#titillium_webthin') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/titillium/titilliumweb-regular-webfont.eot');
  src: url('fonts/titillium/titilliumweb-regular-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/titillium/titilliumweb-regular-webfont.woff') format('woff'), url('fonts/titillium/titilliumweb-regular-webfont.ttf') format('truetype'), url('fonts/titillium/titilliumweb-regular-webfont.svg#titillium_webregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/titillium/titilliumweb-bold-webfont.eot');
  src: url('fonts/titillium/titilliumweb-bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/titillium/titilliumweb-bold-webfont.woff') format('woff'), url('fonts/titillium/titilliumweb-bold-webfont.ttf') format('truetype'), url('fonts/titillium/titilliumweb-bold-webfont.svg#titillium_webbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Titillium Web';
    src: url('fonts/titillium/titilliumweb-extralight-webfont.svg#titillium_webthin') format('svg');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('fonts/titillium/titilliumweb-regular-webfont.svg#titillium_webregular') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('fonts/titillium/titilliumweb-bold-webfont.svg#titillium_webbold') format('svg');
    font-weight: 700;
    font-style: normal;
  }
}
.jstree-proton {
  font-family: 'Titillium Web', sans-serif, Arial, sans-serif;
}




