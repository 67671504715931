@include topPanel(top-panel-platform-curate, 600px, $backdrop_platform_cloudcms);
@include keyPoints(keypoints-platform-curate, $backdrop_platform_cloudcms);

@include topPanel(top-panel-platform-engine, 600px, $backdrop_platform_engine);
@include keyPoints(keypoints-platform-engine, $backdrop_platform_engine);

@include topPanel(top-panel-platform-pipeline, 600px, $backdrop_platform_pipeline);
@include keyPoints(keypoints-platform-pipeline, $backdrop_platform_pipeline);

@include topPanel(top-panel-platform-automation, 600px, $backdrop_platform_automation);
@include keyPoints(keypoints-platform-automation, $backdrop_platform_automation);

@include topPanel(top-panel-platform-train, 600px, $backdrop_platform_train);
@include keyPoints(keypoints-platform-train, $backdrop_platform_train);

@include topPanel(top-panel-platform-applications, 600px, $backdrop_platform_applications);
@include keyPoints(keypoints-platform-applications, $backdrop_platform_applications);
