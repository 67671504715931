@include topPanel(top-panel-7, 450px, $backdrop_pricing);

/// key points
@include keyPoints(keypoints-7, $backdrop_pricing);

.topper.features.pricing {
  margin-top: 0px;
}

.v3-pricing h1,
.v3-pricing h2
{
  color: $backdrop_pricing !important;
}

.pricing-boxes
{
  flex-direction: row;
  align-self: center;
}

.pricing-boxes .pricing-box
{
  background-color: white !important;
  border: 1px black solid;// !important;
  box-shadow: 1px 1px scale-color($backdrop_pricing, $lightness: -10%) !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  padding: 16px !important;
  max-width: 300px !important;
  margin-top: 30px;
}

.pricing-boxes .pricing-box:hover
{
  cursor: pointer !important;
  background-color: #efe9e3 !important;
}

.pricing-boxes .pricing-box.active
{
  //border-left: 10px rgb(19, 170, 82) solid !important;
  //border-left: 10px rgb(19, 82, 170) solid !important;
  border-left: 10px scale-color($backdrop_pricing, $lightness: 40%) solid !important;
}

.pricing-boxes .pricing-box img
{
  width: 48px !important;
  display: flex !important;
  align-self: center !important;
  margin-right: 16px !important;
  margin-left: 0px !important;
}

.pricing-boxes .pricing-box h5
{
  font-size: 20px !important;
  color: #333 !important;
  font-weight: 400 !important;
}

.pricing-boxes .pricing-box p
{
  font-size: 16px !important;
  color: #888 !important;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

.pricing-header
{

}

.pricing-header h2
{
  color: white !important;
  font-size: 42px;
}

.pricing-header p
{
  font-size: 24px !important;
  color: #eee !important;
  font-weight: 300 !important;
}

/////

.v3-pricing-tables
{
  flex-direction: row !important;
  justify-content: center !important;
  margin-bottom: 30px !important;
  font-size: 18px;
  font-weight: 400;
  //margin-left: 10%;
  //margin-right: 10%;
}

.v3-pricing-tables .v3-pricing-table
{
  background-color: white !important;
  border: 1px black solid !important;
  box-shadow: 1px 1px #ddd !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  padding: 16px !important;
  margin-top: 30px !important;
}
.v3-pricing-tables .v3-pricing-table.pick
{
  border: 2px solid rgb(19, 170, 82) !important;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-recommended
{
  height: 30px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  text-align: center;
  font-size: 15px;
}
.v3-pricing-tables .v3-pricing-table.pick .v3-pricing-table-recommended
{
  background-color: black;
  color: white;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-header
{
  font-weight: 400;
  font-size: 30px;
  margin-top: 6px;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-header img
{
  width: 32px;
  margin-right: 8px;
}

.v3-pricing-tables .v3-pricing-table .v3-pricing-table-price
{
  font-weight: 300;
  font-size: 22px;
  color: #888;
  margin-left: 40px;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-signup
{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-signup .btn.btn-outline-primary.rounder,
.v3-pricing .btn.btn-outline-primary.rounder,
//.pricing-questions .btn.btn-outline-primary.rounder
{
  background-color: scale-color($backdrop_pricing, $lightness: 10%)!important;
  color: white !important;
  border-color: scale-color($backdrop_pricing, $lightness: -10%)!important;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-signup .btn.btn-outline-primary.rounder:hover,
//.v3-pricing .btn.btn-outline-primary.rounder:hover
{
  background-color: scale-color($backdrop_pricing, $lightness: 30%)!important;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-description
{
  font-weight: 300;
  font-size: 18px;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-points
{
  margin-top: 20px;
  margin-bottom: 20px;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-points ul li
{
  font-weight: 300;
  font-size: 18px;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-details
{
  text-align: center;
}
.v3-pricing-tables .v3-pricing-table .v3-pricing-table-details a,
//.v3-pricing a.btn.btn-link
{
  color: $backdrop_pricing !important;
}

.v3-pricing-tables.fit2 .v3-pricing-table
{
  min-width: 400px !important;
  max-width: 400px !important;
}

.v3-pricing-tables.fit3 .v3-pricing-table
{
  min-width: 350px !important;
  max-width: 350px !important;
}

.v3-pricing-tables.fit4 .v3-pricing-table
{
  min-width: 300px !important;
  max-width: 300px !important;
}

//

.v3-pricing-button.btn.btn-outline-primary.rounder
{
  background-color: scale-color($backdrop_pricing, $lightness: 10%)!important;
  color: white !important;
  border-color: scale-color($backdrop_pricing, $lightness: -10%)!important;
}
.v3-pricing-button.btn.btn-outline-primary.rounder:hover
{
  background-color: scale-color($backdrop_pricing, $lightness: 30%)!important;
}


.v3-pricing-featured-highlights
{
  border: 1px #ccc solid;
  box-shadow: 1px 1px #555;
  background-color: white;
  border-radius: 8px;
  flex-direction: row !important;
  justify-content: center !important;
  margin-bottom: 30px !important;
  font-size: 18px;
  font-weight: 400;
  margin-left: 20%;
  margin-right: 20%;
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

.v3-pricing-featured-highlights h3
{
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 22px;
  color: $backdrop_pricing;
}

.v3-pricing-featured-highlights .items dl
{
  display: grid;
  //grid-template: auto / 10em 1fr;
  //grid-template: 1fr / 10em 1fr;
  grid-template-rows:    1fr;
  grid-template-columns: repeat(2, 1fr);
}

.v3-pricing-featured-highlights .items dt
{
  //background: #fee;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: 400;
}

.v3-pricing-featured-highlights .items dd
{
  //background: hsl(220, 10%, 95%);
}

.v3-pricing-featured-highlights .items dt,
.v3-pricing-featured-highlights .items dd
{
  //margin: 0;
  //padding: .3em .5em;
  //border-top: 1px solid rgba(0,0,0,.1);
}


.v3-pricing .btn.btn-outline-primary
{
  color: $backdrop_pricing !important;
  border-color: scale-color($backdrop_pricing, $lightness: -10%)!important;
  font-size: 1.15em;
  box-shadow: 1px 1px #eee;
}
.v3-pricing .btn.btn-outline-primary:hover
{
  background-color: scale-color($backdrop_pricing, $lightness: 30%) !important;
}

// free
.pricing-table-holder.pricing-table-holder-free table.pricing-table thead tr th,
.pricing-table-holder.pricing-table-holder-free table.pricing-table tbody tr td
{
  width: 30%;
}
.pricing-table-holder.pricing-table-holder-free table.pricing-table thead tr th:first-child,
.pricing-table-holder.pricing-table-holder-free table.pricing-table tbody tr td:first-child
{
  width: 40%;
}

// hosted
.pricing-table-holder.pricing-table-holder-hosted table.pricing-table thead tr th,
.pricing-table-holder.pricing-table-holder-hosted table.pricing-table tbody tr td
{
  width: 15%;
}
.pricing-table-holder.pricing-table-holder-hosted table.pricing-table thead tr th:first-child,
.pricing-table-holder.pricing-table-holder-hosted table.pricing-table tbody tr td:first-child
{
  width: 40%;
}

table.pricing-table .pricing-table-detail.community i.fa
{
  color: #aaa;
}

table.pricing-table .pricing-table-detail.commercial i.fa
{
  color: #44bd32;
  font-weight: 800;
}


.pricing-questions
{
  margin: 0px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: radial-gradient(circle, $backdrop_pricing, $backdrop_pricing);
  align-self: center;
}

.pricing-questions .pricing-questions-left h3
{
  color: white;
  margin-bottom: 20px;
}
.pricing-questions .pricing-questions-left p
{
  color: white;
  font-size: 1.1em;
}

.pricing-questions .pricing-questions-right
{
  align-self: center;
  text-align: center;
}

.getstarted
{
  background: #fafafa;
  align-self: center;
}

.getstarted .container
{
  padding-right: 0px;
  padding-left: 0px;
}

.getstarted .getstarted-holder
{
  margin: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  align-self: center;

}
.getstarted .getstarted-holder .getstarted-left h3
{
  color: $backdrop_home;
  margin-bottom: 20px;
  font-size: 32px;
}
.getstarted .getstarted-holder .getstarted-left p
{
  color: $backdrop_home;
  font-size: 1.1em;
}
.getstarted .getstarted-holder .getstarted-right
{
  align-self: center;
  text-align: center;
}

.v3-pricing i.fa.fa-check-circle
{
  color: scale-color($backdrop_pricing, $lightness: 10%)!important;
}

.container-fluid.v3-pricing
{
  margin-top: -70px;
}

//.v3-pricing .btn.btn-outline-primary.btn-coming-soon1,
//.v3-pricing .btn.btn-outline-primary.btn-coming-soon1:hover
//{
//  color: #777 !important;
//  border-color: #777 !important;
//  background-color: transparent !important;
//  font-size: 1.15em;
//  box-shadow: 1px 1px #eee;
//}
