$main-bg-grad-1: scale-color($spot_1, $lightness: -10%);
$main-bg-grad-2: $spot_1;
$main-bg-grad-3: scale-color($spot_1, $lightness: 10%);





.home-image
{
  margin-top: 30px;
  margin-bottom: 30px;
}

.home-image img
{
  width: 100%;
  //-webkit-filter: blur(5px);
  //-webkit-clip-path: circle(50% at center);
}

ul.home-list
{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  margin-bottom: 25px;

  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;

  margin-top: 25px !important;
  margin-bottom: 25px !important;

  margin-left: 0px !important;
  padding-left: 0px !important;
}

ul.home-list li
{
  color: white;
  line-height: 1.35em;
  font-size: 1.35em !important;
  margin-bottom: 8px;
}

ul.home-list li > i
{
  //color: rgb(255, 218, 0);
  color: #fed86e !important;
  margin-right: 4px !important;
  text-shadow: 1px 1px 1px #444;
}

p.homesum
{
  color: #fed86e !important;
}




/** TOPPER SECTION **/

section.topper
{
  background: transparent !important;
  padding-bottom: 0px;
}

section.topper .topper-left
{
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  padding-right: 40px;
  //justify-content: center;
  justify-content: flex-start;
}

section.topper .topper-left h1
{
  margin-bottom: 30px;
  color: $gray-700;
  font-size: 2.6em;
  font-weight: 600;
  text-align: left;
}

section.topper .topper-right
{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
}

section.topper .topper-left .buttons,
section.topper .topper-right .buttons,
.buttons
{
  text-align: center;
}
.buttons.buttons-spaced > *:not(:last-child)
{
  margin-right: 20px;
}
.buttons.buttons-spaced
{
  margin-top: 30px;
}

section.topper .topper-left p
{
  color: $spot_1;
  font-size: $p_size_1;
  line-height: $p_size_1;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
}

section.topper .topper-left ul
{
  //list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 60px;
}

section.topper .topper-left ul.highlights
{
  margin-top: 0px;
  margin-bottom: 20px;
}

section.topper .topper-left ul.highlights p
{
  margin-left: 21px;
  margin-right: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

section.topper .topper-left ul.highlights li i
{
  margin-right: 6px;
  color: #aaa;
}

section.topper .topper-left ul.highlights li
{
  color: white;
  font-size: $p_size_1;
  line-height: $p_size_1;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  margin-bottom: 0px;
}

section.topper.home div.container-fluid
{
  //background: linear-gradient(33deg, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 60%, rgba($backdrop_home,1) 100%);
  //background: url(/static/waves/blue-1-top-r.png);
  //background-size: cover;
  //background-repeat: no-repeat;
}

section.topper ul.highlights li p
{
  padding-top: 10px;
  font-size: 0.8em;
  color: #777;
  font-weight: 400;
}

section.topper ul.highlights li i.fa
{
  padding-right: 8px;
}

section.topper .previews
{
  margin-top: 30px;
  margin-bottom: 30px;
}

section.topper .previews img
{
  height: 128px;
}





.key-features
{
  margin-bottom: 20px;
}

.key-features-holder
{
  text-align: center;
}

.key-features-holder img
{
  width: auto;
  height: 128px;

}

.key-features-holder h3
{
  text-transform: none;
  font-weight: 600;
  font-size: 1.15em;
  margin-bottom: 10px;
  color: #444;
  text-align: left;
}

.key-features-holder h3 a
{
  color: #444;
  font-weight: 600;
}

.key-features-holder p
{
  text-align: left;
  margin-bottom: 30px;
  font-size: 16px;
  color: #111;
}

.key-features-holder h3
{
  margin-top: 0px;
  text-transform: none;
}

.key-features-block
{
  //height: 360px;
  //margin-top: 40px;
  //margin-bottom: 40px;
}






.block-header
{
  /*margin-top: 100px;*/
}

.block-header2
{
  margin-top: 100px;
}

.block-columns .block-column-image-holder
{
  text-align: center;
}

.block-columns .block-column-image-holder IMG
{
  width: 100px;
  opacity: 0.7;
}

.block-column-image-holder2
{
  display: inline-block;
  margin: 10px;
}
.block-column-image-holder2 img
{
  filter: grayscale(100%);
  max-width: 200px;
  max-height: 150px;
  opacity: 0.7;
}

.block-columns h4
{
  text-align: center;
}

.block-columns .block-column-button-holder
{
  text-align: center;
}

.block-columns p
{
  /*text-align: justify;*/
  text-align:center;
}

.block-columns p.block-column-details
{
  font-size: 16px;
  text-align: left;
}

.block-column-image-holder
{
  padding: 20px;
}









.row.devblocks
{
}

.row.devblocks > div
{
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}

.row.devblocks img
{
  opacity: 0.8;
  max-width: 125px;
  margin: 10px;
}

.row.devblocks img:hover
{
  opacity: 1;
}

.row.devblocks h5
{
  margin-bottom: 15px;
}






.home-integrations img
{
  max-width: 200px;
  padding: 20px;
}









// home; custom product




.custom-product blockquote
{
  padding: 0px !important;
  margin-top: 40px;
}


.whycloudcms-block h3
{
  margin-top: 20px;
  text-transform: none;
  margin-bottom: 20px;
}

.whycloudcms-block p
{
  font-size: $p_size_2;
}

.whatiscloudcms p
{
  font-size: $p_size_2;
}



/** headlines **/

.headlines-holder
{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /** https://png-pixel.com/ **/
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8LwkAAh0BGumlBj4AAAAASUVORK5CYII=);
  border-radius: 16px;
  padding: 16px;
}

.headlines-holder .headline
{

}

.headlines-holder h3,
.headlines-holder h3 a
{
  color: white;
  margin-bottom: 30px;
  text-align: center;
  text-decoration: underline;
}

.headlines-holder ul
{
  margin-left: -15px;
  color: white;
}


.headlines-holder .headline h4,
.headlines-holder .headline h4 a
{
  color: $yellowback-color;
  font-weight: 400;
}

.headlines-holder .headline p.timestamp
{
  font-size: 0.8em;
  color: white;
}

.headlines-holder .headline p.summary
{
  color: white;
}









.bottomcta
{
  /*
  background-color: #0176d3;
  color: #fff;
  min-height: 320px;
  background: radial-gradient(circle 800px at center 400px,#42A5F5 0,#000 200%) !important;
  */
  //background-color: #00953b;
  //background:linear-gradient( -70deg,#237fba 0%,#298dbf,#306bc4 100% );
  background-color: scale-color($spot_1, $lightness: -10%);
  color: #fff;
  min-height: 280px;
}

.bottomcta h2
{
  padding-top: 60px;
  color: #fff;
}

.bottomcta p
{
  color: #fff;
}

.check
{
  color: $check_color;
}

.content-section.autobg
{
  background-color: $white;
}
.content-section.autobg:nth-of-type(even)
{
  background-color: #fbfbfb;
  border-bottom: 1px #eee solid;
  border-top: 1px #eee solid;
}

.content-section.white
{
  background-color: $white;
}

.content-section.offwhite
{
  background-color: #fbfbfb;
  border-bottom: 1px #eee solid;
  border-top: 1px #eee solid;
}


.on-premise-holder
{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.logos
{
  max-width: 300px;
  text-align: center;
}

.logos a:not(:first-child) img
{
  margin-top: 20px;
}

.screenshot-holder
{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
img.screenshot
{
  border: 1px #ccc solid;
  box-shadow: 2px 2px 10px #ccc;
}

/////// top panel
@include topPanel(top-panel-1, 600px, $backdrop_home);


/// key points
@include keyPoints(keypoints-home, $backdrop_home);

.keypoints.keypoints-home.whatsnew
{
  //background: linear-gradient(#026b94, #16bbfc);
  color: white;
  background: radial-gradient(circle, #555, #333) !important;
  border: 0px !important;
  box-shadow: 2px 2px 1px #000;
  //box-shadow: none;
  //border-left: 10px white solid !important;
  //border-bottom-left-radius: 8px !important;
  //border-top-left-radius: 8px !important;
  //border-top: 1px white solid !important;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.keypoints.keypoints-home.whatsnew,
.keypoints.keypoints-home.whatsnew h2,
.keypoints.keypoints-home.whatsnew .whatsnew-body,
.keypoints.keypoints-home.whatsnew .whatsnew-date
{
  color: white !important;
}

.keypoints.keypoints-home.whatsnew h2
{
  color: #fed86e !important;
}

.keypoints.keypoints-home.whatsnew .whatsnew-image
{
  text-align: center;
}
.keypoints.keypoints-home.whatsnew .whatsnew-image img
{
  max-width: 200px;
}
.keypoints.keypoints-home.whatsnew .whatsnew-ctas
{
  margin-top: 40px;
  text-align: center;
}

.why-cloudcms-summary
{
  margin-left: 40px;
}

.home .media img
{
  width: 48px;
}

.home .media p
{
  padding-left: 0px;
  color: #777;
}

//.home .media p a
//{
//  font-weight: 200;
//}


//////// z111

$tc1: #F44336;
$tc2: scale-color($tc1, $lightness: -40%);
.top-panel-1 .svg-row svg
{
  //visibility: hidden;
}




section.topper.home .topper-right
{
  justify-content: flex-start;
}

section.topper.home .topper-left p em
{
  color: $yellowback-color;
  //font-weight: 400;
}

section.topper.top-panel-1.home .topper-right .buttons .btn.rounder
{
  color: red !important;
}

.topper-right .topper-right-image
{
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
}

section.topper.home .content-section.first-section
{
  margin-top: -80px;
}




















#flipper {
  display: inline-block;
  filter: url(#threshold) blur(0.6px);
}

#flipper-text1,
#flipper-text2 {
  position: absolute;
  width: 600px;
  display: inline-block;
  top: -42px;

  //font-family: "Raleway", sans-serif;
  //font-size: 80pt;

  //text-align: left;

  //user-select: none;
}

.figspacedleft figure
{
  margin-left: 20px !important;
}

.figspacedleft figure figcaption
{
  text-align: center !important;
  color: #555;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.content-section .section-heading,
.content-section.landing2 .section-heading,
{

}

.content-section .section-heading h2,
.content-section.landing2 .section-heading h2
{
  font-size: 38px!important;
  text-transform: uppercase !important;
}

.content-section .section-heading h2.title1,
.content-section.landing2 .section-heading h2.title1
{
  color: #208ec7 !important;
}

.content-section .section-heading h2.title2,
.content-section.landing2 .section-heading h2.title2,
{
  color: $darktext !important;
}

.content-section .section-heading p.message,
.content-section.landing2 .section-heading p.message
{
  display: inline-block; margin-top: 20px; max-width: 60%;
}

.lake::after {
  background: linear-gradient(rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 0.09) 8.85%, rgb(255, 255, 255) 100%);
  height: 80px;
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
}

.home-row
{

}

ul.home-architecture
{
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  list-style: square outside;
  margin-left: 20px;
  padding-left: 0px;
  text-align: left;
  padding-right: 0px;
}

ul.home-architecture li
{
  color: $darktext !important;
}