.status-icon
{
  font-size: 0.8em;
  //font-weight: 100;
}

.status-icon.none
{
  color: #00EE00;
}

.status-icon.minor,
.status-icon.major
{
  color: #EEEE00;
}

.status-icon.critical
{
  color: #EE0000;
}