@include topPanel(top-panel-landing-40, 600px, $backdrop_landing_40);
@include keyPoints(keypoints-landing-40, $backdrop_landing_40);

@include topPanel(top-panel-landing-ai, 600px, $backdrop_landing_ai);
@include keyPoints(keypoints-landing-ai, $backdrop_landing_ai);


.content-section.landing2 p
{
  padding-left: 10px !important;
  color: #333 !important;
  font-size: 18px !important;
}

.content-section.landing2 h2
{
  font-size: 38px !important;
  color: $darktext !important;
}

.content-section.landing2 h3
{
  //text-transform: uppercase;
  //font-weight: 600;
  font-size: 26px !important;
  color: #1E88E5 !important;
  //font-weight: 200;
  padding-left: 10px !important;
}

.content-section.landing2 h4
{
  //text-transform: uppercase;
  //font-weight: 400;
  font-size: 20px !important;
  color: #EF6C00 !important;
  font-weight: 200 !important;
  padding-left: 10px !important;
}

.content-section.landing2 h4 > i
{
  margin-right: 6px !important;
}

.content-section.landing2 .row.point
{
  margin-top: 15px !important;
}

.content-section.landing2 .cc5
{
  padding-left: 28px !important;
}