.trial
{
  display: flex;
  flex: 1;
  flex-direction: column;
  //background: linear-gradient(33deg,#fff 55%,#fff 58%,#39d5ff 100%);
}

.tcenter
{
  padding-left: 35%;
  padding-right: 35%;
  justify-content: center;
  margin-bottom: 200px;
}

body.conversion header
{
  background: radial-gradient(circle,#555,#333);
}

.trial-confirm
{

}

.trial-confirm .subdomain-holder
{
  display: flex;
  flex-direction: row;
}

.trial-confirm .subdomain-holder .subdomain-front
{
  display: flex;
  align-content: flex-start;
}

.trial-confirm .subdomain-holder .subdomain-back
{
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-left: 4px;
}

.trial-confirm .subdomain-message-holder
{

}

.subdomain-taken
{
  color: #dc3545;
}

.trial-confirm .regionflag
{
  height: 32px;
}

#subdomain::placeholder
{
  color: #333;
  font-weight: 400;
  opacity: 0.5;
}



.subdomain-available
{
  color: #148d02;
  font-size: 0.9em;
}
