/*!
 * /*
 * Copyright 2021 Gitana Software, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * You may obtain a copy of the License at
 * 	http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * For more information, please contact Gitana Software, Inc. at this
 * address:
 *
 *   info@gitana.io
 * * /
 *
 */
/** Added to outer field elements to hide them **/
.alpaca-hidden
{
    display: none;
}

/** Added to every field outer element **/
.alpaca-field
{

}

/** Added to every container outer field element **/
.alpaca-container
{

}

/** Added to the optional label (or legend) accompanying any container **/
.alpaca-container-label
{

}

/** Added to every container item field within a container **/
.alpaca-container-item
{

}

/** Added to the first container item field within a container **/
.alpaca-container-item-first
{

}

/** Added to the last container item field within a container **/
.alpaca-container-item-last
{

}

/** Added to a container to indicate it is empty **/
.alpaca-container-empty
{

}

/** Added to the dom element holding the toolbar for an array **/
.alpaca-array-toolbar
{

}

/** Added to a button in the array toolbar that triggers an action **/
.alpaca-array-toolbar-action
{

}

/** Added to the dom element holding the actionbar for each array item **/
.alpaca-array-actionbar
{

}

/** Added to a button in an array item actionbar that triggers an action **/
.alpaca-array-actionbar-action
{

}

/** Added to every control outer field element **/
.alpaca-control
{

}

/** Added to the optional label accompanying any control **/
.alpaca-control-label
{

}

/** ******************************************************** **/
/**                        STATE LABELS                      **/
/** ******************************************************** **/

/** Added to top-most control when rendering in display mode **/
.alpaca-display
{

}

/** Added to top-most control when rendering in create mode **/
.alpaca-create
{

}

/** Added to top-most control when rendering in edit mode **/
.alpaca-edit
{

}

/** Added to any field that is optional **/
.alpaca-required
{

}

/** Added to any field that is required **/
.alpaca-optional
{

}

/** Added to any field that is readonly **/
.alpaca-readonly
{

}

/** Added to any field that is disabled **/
.alpaca-disabled
{

}

/** Added to the container that holds Alpaca field helper text **/
.alpaca-helper
{

}

/** Added to form containers that are rendering in horizontal mode **/
.alpaca-horizontal
{

}

/** Added to form containers that are rendering in vertical mode **/
.alpaca-vertical
{

}

/** Added to the top most container element **/
.alpaca-top
{

}

/** Added to fields that are valid **/
.alpaca-valid
{

}

/** Added to fields that have run through validation and are invalid **/
.alpaca-invalid
{

}

/** Added to fields that have run through validation and are invalid but are currently hiding invalidation errors **/
.alpaca-invalid-hidden
{

}

/* Added to additional DOM elements to mark validation messages */
.alpaca-message
{

}

/** Added to messages that are for invalidation states that are current hidden (hideInitValidationError) **/
.alpaca-message-hidden
{

}

/** Added to buttons that are marked disabled */
.alpaca-button-disabled
{

}



/** ******************************************************** **/
/**                           ICONS                          **/
/** ******************************************************** **/

.alpaca-icon-helper
{

}

.alpaca-icon-required
{

}


/** ******************************************************** **/
/**                           OTHER                          **/
/** ******************************************************** **/

.alpaca-autocomplete
{
}



/** Added to indicate hover state **/
.alpaca-hover
{
}

/** General purpose HTML clear **/
.alpaca-clear
{
    clear: both;
}

.alpaca-float-right
{
    float: right;
}


/** ************************************** **/
/** TWITTER TYPEAHEAD                      **/
/** ************************************** **/

.twitter-typeahead .tt-dropdown-menu
{
    background-color: white;
    border: 1px #ccc solid;
    padding: 10px;
    color: #999;
    width: 100%;
    padding-bottom: 0px;
}

.twitter-typeahead .tt-dropdown-menu P
{
    font-size: 1em;
}

.tt-suggestion.tt-is-under-cursor
{
    background-color: #ccc;
    color: #333;
}

.tt-dropdown-menu
{
    background-color: white;
    border: 1px solid rgb(204, 204, 204);
    width: 100%;
}




/** ************************************** **/
/** EDITOR FIELD                           **/
/** ************************************** **/

.alpaca-controlfield-editor
{
    position: relative;
    width: 100%;
    height: 300px;
    border: 1px #ccc solid;
}

.alpaca-controlfield-editor .control-field-editor-el
{
    position:absolute;
    top:0;
    bottom:0;
    left: 0;
    right:0
}



/** ************************************** **/
/** DATEPICKER (jQueryUI)                  **/
/** ************************************** **/

#ui-datepicker-div
{
    display: none;
    background-color:white;
    z-index: 9999999 !important;
    width: 22em !important;
}

/* Fix for Chrome issue with Button text */
.ui-button .ui-button-text {
    white-space: nowrap;
}

.ui-datepicker
{
    z-index: 100;
}








/** ************************************** **/
/** FILE UPLOAD (jQueryUI)                 **/
/** ************************************** **/

.alpaca-fileupload-container
{
    border: 1px #ccc solid;
    padding: 10px;
    border-radius: 5px;
}

.alpaca-fileupload-container .row
{
    margin-bottom: 10px;
}

.alpaca-fileupload-well
{
    /*border: 1px #ccc solid;*/
    padding: 10px;
    border-radius: 5px;
    min-height: 100px;
}

.alpaca-fileupload-container table
{
    border: 1px #ccc solid;
    padding: 10px;
    border-radius: 5px;
}

.alpaca-fileupload-well p
{
    padding-top: 20px;
    color: #888;
}

.alpaca-fileupload-well table tbody.files tr td.name
{
    word-wrap: break-word;
}







/** ************************************** **/
/** ACE EDITOR                             **/
/** ************************************** **/

.ace_editor
{
    border: 1px solid rgb(204, 204, 204);
}



/** ************************************** **/
/** CK EDITOR                             **/
/** ************************************** **/

.alpaca-field-ckeditor.alpaca-invalid > .cke
{
    border-color: #f04124;
}


/** ************************************** **/
/** OPTIONTREE                             **/
/** ************************************** **/

.alpaca-field-optiontree {

}

.alpaca-field-optiontree .optiontree {

}

.alpaca-field-optiontree .optiontree .optiontree-selector {

}

.alpaca-field-optiontree.optiontree-horizontal {

}

.alpaca-field-optiontree.optiontree-horizontal .optiontree {
    display: inline-block;
}

.alpaca-field-optiontree.optiontree-horizontal .optiontree .optiontree-selector {
    display: inline-block;
    padding-left: 4px;
}

.alpaca-field-optiontree.optiontree-horizontal input {
    display: inline-block;
    width: auto;
}

.alpaca-field-optiontree.optiontree-horizontal label {
    display: block;
}



/** ************************************** **/
/** TABLE                                  **/
/** ************************************** **/

.alpaca-field-table .alpaca-field-tablerow label.control-label,
table.dt-rowReorder-float .alpaca-field-tablerow label.control-label
{
    display: none;
}

.alpaca-field-table .actionbar, .table .actionbar,
table.dt-rowReorder-float .actionbar, .table .actionbar
{
    white-space: nowrap;
    text-align: center;
}

.alpaca-field-table table tr td .alpaca-field,
table.dt-rowReorder-float tr td .alpaca-field
{
    width: 100%;
}

.alpaca-field-table table tr td .alpaca-field .form-control,
table.dt-rowReorder-float tr td .alpaca-field .form-control
{
    width: 100%;
}

.alpaca-field-table .form-group,
table.dt-rowReorder-float .form-group
{
    margin-bottom: 0px;
}

.alpaca-field-table .form-group.alpaca-field-checkbox,
table.dt-rowReorder-float .form-group.alpaca-field-checkbox
{
    text-align: center;
}

.alpaca-field-table .alpaca-control.checkbox,
table.dt-rowReorder-float .alpaca-control.checkbox
{
    padding-top: 6px;
}

.alpaca-field-table table tr td.actionbar .alpaca-array-actionbar,
table.dt-rowReorder-float tr td.actionbar .alpaca-array-actionbar
{
    padding-bottom: 0px;
    display: inline-block;
}

.alpaca-field-table .actionbar .alpaca-array-actionbar.btn-group,
table.dt-rowReorder-float .actionbar .alpaca-array-actionbar.btn-group
{
    width: auto;
}

.alpaca-field-table .alpaca-table-column-hidden,
table.dt-rowReorder-float .alpaca-table-column-hidden
{
    display: none;
}

.alpaca-field-table .alpaca-table-reorder-index-header,
table.dt-rowReorder-float .alpaca-table-reorder-index-header
{
    display: none;
}

.alpaca-field-table .alpaca-table-reorder-index-cell,
table.dt-rowReorder-float .alpaca-table-reorder-index-cell
{
    display: none;
}

.alpaca-field-table .alpaca-table-reorder-draggable-header,
table.dt-rowReorder-float .alpaca-table-reorder-draggable-header
{

}

.alpaca-field-table .alpaca-table-reorder-draggable-cell,
table.dt-rowReorder-float .alpaca-table-reorder-draggable-cell
{
    color: #bbb;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    padding-top: 11px;
    cursor: pointer;
}






.alpaca-field-radio.disabled .alpaca-control.radio
{
    color: grey;
}

.alpaca-field-radio.disabled .alpaca-control.radio label
{
    cursor: inherit;
}


.alpaca-control.radio
{
    min-height: inherit;
    height: inherit;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 10px;
}

.alpaca-control.radio label
{
    line-height: 20px;
}

/** multiselect **/

.has-error .multiselect
{
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.btn.multiselect
{
    white-space: normal;
}

.alpaca-field-table > legend
{
    display: none;
}

/*** CHOOSER **/

.alpaca-field-chooser
{

}

.alpaca-field-chooser .alpaca-control
{
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-header
{
    min-height: 34px;
    margin-bottom: 20px;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items
{
    background-color: white;
    border: 1px #ccc solid;
    border-radius: 4px;
    overflow-y: scroll;
    overflow-y: -moz-scrollbars-vertical;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items .chooser-item
{
    min-height: 34px;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items .chooser-item.disabled .chooser-item-text
{
    color: #ccc;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items .chooser-item:not(:last-child)
{
    border-bottom: 1px #ddd solid;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items .chooser-item .chooser-item-text
{
    margin-left: 10px;
    line-height: 34px;
    display: inline-block;
}

.alpaca-field-chooser .alpaca-control .chooser-panel .chooser-panel-items .chooser-item .chooser-item-buttons
{
    float: right;
    margin: 5px;
}

.alpaca-field-chooser .chooser-item-message
{
    color: #aaa;
}

/** TOOLBAR **/
.alpaca-array-toolbar
{
    display: inline-flex;
}

.alpaca-array-toolbar.alpaca-array-toolbar-position-top
{
    margin-bottom: 10px;
}

.alpaca-array-toolbar.alpaca-array-toolbar-position-bottom
{
    margin-top: 10px;
}


/** ACTIONBAR **/
.alpaca-array-actionbar
{
    display: inline-flex;
}

.alpaca-array-actionbar .alpaca-array-actionbar-action
{
    min-width: 32px;
}

.alpaca-array-actionbar.alpaca-array-actionbar-top
{
    padding-bottom: 10px;
}

.alpaca-array-actionbar.alpaca-array-actionbar-bottom
{
    padding-top: 10px;
}

.alpaca-array-actionbar.alpaca-array-actionbar-left
{
    padding-right: 10px;
}

.alpaca-array-actionbar.alpaca-array-actionbar-right
{
    padding-left: 10px;
}

.alpaca-array-actionbar, .alpaca-array-actionbar.btn-group
{
}




/** ICONS **/
.alpaca-icon
{
    display: inline-block;
    color: #333;
}

.alpaca-icon.alpaca-icon-add::before
{
    content: "\002B";
}
.alpaca-icon.alpaca-icon-remove::before
{
    content: "\002D";
}
.alpaca-icon.alpaca-icon-up::before
{
    content: "\2191";
}
.alpaca-icon.alpaca-icon-down::before
{
    content: "\2193";
}
.alpaca-icon.alpaca-icon-expanded::before
{
    content: "\25BC";
}
.alpaca-icon.alpaca-icon-collapsed::before
{
    content: "\25B6"
}














.alpaca-container-label
{
    margin-top: 20px;
}

legend.alpaca-container-label
{
    margin-top: 0px;
}




.alpaca-array-item-move
{
    width: 34px; 
    height: 34px;
    padding: 10px;
}

.alpaca-array-item-flex-container
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.alpaca-array-item-flex-1
{
    flex-grow: 1;
}

.alpaca-array-item-move
{
    display: flex;
    flex: 0.1;
}

.alpaca-array-item-move:hover
{
    cursor: move;
}

.alpaca-array-actionbar
{
    overflow: hidden;
}

.alpaca-field-object
{
    border: 1px #eee solid;
    border-radius: 4px;
    padding: 10px;
}

.alpaca-display h3
{
    margin-top: 0px;
}

/*
.alpaca-field-object > .alpaca-container.alpaca-container-has-items
{
    border: 1px #eee solid;
    border-radius: 4px;
    padding: 10px;
}
*/

.alpaca-field-array
{
    border: 1px #eee solid;
    border-radius: 4px;
    padding: 10px;
}

/*
.alpaca-field-array > .alpaca-container.alpaca-container-has-items
{
    border: 1px #eee solid;
    border-radius: 4px;
    padding: 10px;
}
*/

.alpaca-control-buttons-container
{
    margin-top: 10px;
}

.alpaca-container-item > .alpaca-container > .alpaca-field-object
{
    border: 0px;
}

.alpaca-form-buttons-container
{
    margin-top: 10px;
    text-align: right;
}

.alpaca-container > .form-group
{
    margin-left: 0px;
    margin-right: 0px;
}

.alpaca-field-hidden
{
    display:none;
}

.alpaca-field-object.alpaca-top
{
    border: 0px;
}

.alpaca-field-array.alpaca-top
{
    border: 0px;
}

.alpaca-container-item:not(:first-child)
{
    margin-top: 10px;
}

.alpaca-container .alpaca-container-item:last-child
{
    margin-bottom: 0px;
}

.alpaca-field-object.alpaca-top .alpaca-container
{
    border: 0px;
}

.alpaca-field-select .btn-group
{
    display:block;
}

.alpaca-field-select .btn-group button.multiselect
{
    float: none;
}

/*
.bootstrap-timepicker-widget table td input
{
    width: 50px !important;
}
*/




/** FIElDS **/
.alpaca-field.alpaca-field-upload .fileupload-active-zone
{
    margin-top: 50px;
    margin-bottom: 50px;
}
.alpaca-field.alpaca-field-upload .template-download TD.error
{
    color: red;
    word-break: break-all;
}
.alpaca-field.alpaca-field-upload .template-download TD.error .label
{
    color: red;
    font-size: 16px;
    text-align: left;
}

.alpaca-field.alpaca-field-upload .template-upload TD.error
{
    color: red;
    word-break: break-all;
}
.alpaca-field.alpaca-field-upload .template-upload TD.error .label
{
    color: red;
    font-size: 16px;
    text-align: left;
}

.alpaca-field.alpaca-field-address .alpaca-field-address-mapcanvas
{
    height: 250px;
}

.alpaca-field.alpaca-field-image .alpaca-image-display
{
    margin-top: 20px;
}

.alpaca-field-text-max-length-indicator
{
    color: #aaa;
}

.alpaca-field-text-max-length-indicator.err
{
    color: #aa0000;
}



/** WIZARD **/

.alpaca-wizard .alpaca-wizard-nav li .chevron
{
    position: absolute;
    top: 0;
    right: -14px;
    display: block;
    border: 27px solid transparent;
    border-right: 0;
    border-left: 14px solid #d4d4d4;
}

.alpaca-wizard .alpaca-wizard-nav li .chevron:before
{
    position: absolute;
    top: -27px;
    right: 1px;
    display: block;
    border: 27px solid transparent;
    border-right: 0;
    border-left: 14px solid #fcfcfc;
    content: "";
}

.alpaca-wizard .alpaca-wizard-nav li div.holder
{
    padding-left: 30px;
    padding-right: 20px;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.alpaca-wizard .alpaca-wizard-nav li:first-child div.holder
{
    padding-left: 10px;
}

.alpaca-wizard .alpaca-wizard-nav li div.holder div.title
{
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.alpaca-wizard .alpaca-wizard-nav li div.holder div.description
{
    color: #aaa;
    font-size: 12px;
}

.alpaca-wizard .alpaca-wizard-nav li.completed div.holder div.title
{
    color: #333;
}

.alpaca-wizard .alpaca-wizard-nav li.completed div.holder div.description
{
    color: #333;
}

.alpaca-wizard .alpaca-wizard-nav li.visited:hover
{
    cursor: pointer;
}

.alpaca-wizard .alpaca-wizard-nav li.visited div.holder div.title
{
}

.alpaca-wizard .alpaca-wizard-nav li.visited div.holder div.description
{
}

.alpaca-wizard .alpaca-wizard-nav li.active:hover
{
    cursor: default;
}

.alpaca-wizard .alpaca-wizard-nav li.active div.holder div.title
{
    color: #206199;
}

.alpaca-wizard .alpaca-wizard-nav li.active div.holder div.description
{
    color: #206199;
}

.alpaca-wizard .alpaca-wizard-nav li.disabled div.holder div.title
{
    color: #ccc;
}

.alpaca-wizard .alpaca-wizard-nav li.disabled div.holder div.description
{
    color: #ccc;
}

.alpaca-wizard .alpaca-wizard-nav .alpaca-wizard-back
{
    background-color: #fcfcfc;
}

.alpaca-wizard .alpaca-wizard-buttons
{
    margin-top: 20px;
}

.alpaca-wizard .alpaca-wizard-progress-bar
{
    margin-top: 20px;
}

.alpaca-control.disabled label
{
    color: #aaa;
}

.alpaca-required-indicator
{
    padding-left: 5px;
    font-style: italic;
    font-weight: 200;
    color: #888;
}

.alpaca-field.alpaca-edit .CodeMirror-fullscreen
{
    z-index: 100;
}




/** **/
input.alpaca-control::placeholder {
    color: #dee5ea;
}



