
$topbar-background: #444; //radial-gradient(circle, #555, #333);
$topbar-background-color: #444;
$topbar-text-color: white;

$header-background: $whiteback1 !default;

// intro section paragraphs
$p_size_1: 1.6em;

// body sections
//$p_size_2: 1.15em;
$p_size_2: 17px;



html {
  scroll-padding-top: 100px;
}


body {
  background-color: $white;
  //text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

header
{
  border-top: 0px;
  padding: 0px;
  height: 140px;
  background: $header-background;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

header > div.container
{
  height: 130px;
}

.snap-holder
{
  display: flex;
  flex: 1;
  flex-direction: row;
}

.snap-holder .snap-left
{
  width: 250px;
  align-self: center;
  text-align: left;
}

.snap-holder .snap-center
{
  flex: 1;
  align-self: center;
  justify-content: center;
}

.snap-holder .snap-right
{
  width: 250px;
  align-self: center;
  text-align: right;
}


header .topmenu nav.navbar
{
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

header .topmenu nav.navbar ul.navbar-nav li.nav-item
{
  margin-left: 10px;
  margin-right: 10px;
}
header .topmenu nav.navbar ul.navbar-nav > li.nav-item:first-child
{
  margin-left: 0px;
}
header .topmenu nav.navbar ul.navbar-nav > li.nav-item:last-child
{
  margin-right: 0px;
}

//header .topmenu nav.navbar ul.navbar-nav > li.nav-item,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item > a,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item > a:hover,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item > a:visited
{
  color: $darktext;
  text-decoration: none;
}

//header .topmenu nav.navbar ul.navbar-nav > li.nav-item.active,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item.active > a,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item.active > a:hover,
header .topmenu nav.navbar ul.navbar-nav > li.nav-item.active > a:visited
{
  font-weight: 600;
  text-decoration: none;
  color: #0074ad;
}

header .topmenu > .row
{
  align-items: center;
  width: 100%;
}

header .topmenu
{
  width: 100%;
  display: flex;
  height: 86px;
  border-bottom: 1px #ccc solid;
}

header .topmenu .navbar-brand img
{
  //margin-left: 30px;
  //margin-top: 10px;
  //width: 110px;
  margin-left: 50px;
  margin-right: 50px;
  //filter: grayscale(100%);
  //1a9790
  //filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(180deg) brightness(95%) contrast(80%) drop-shadow(1px 1px 1px #d8d8d8);
  //filter: grayscale(100%) invert(48%) sepia(13%) saturate(3207%) hue-rotate(180deg) brightness(95%) contrast(80%) drop-shadow(1px 1px 1px #d8d8d8);
  //filter: brightness(2) grayscale(50%) drop-shadow(1px 1px 1px #d8d8d8);
  //filter: grayscale(100%) invert(48%) sepia(13%) saturate(3207%) hue-rotate(180deg) brightness(60%) drop-shadow(1px 1px 1px #d8d8d8);
}

//header .topmenu .yamm .dropdown .dropdown-menu
//{
//  display: block;
//
//  transition: all 0.1s ease-out;
//  visibility: hidden;
//  overflow: hidden;
//  max-height: 0px;
//}
//
//header .topmenu .yamm .dropdown:hover>.dropdown-menu {
//  transition: all 0.3s ease-in;
//  visibility: visible;
//  max-height: 800px;
//}

header .topmenu .yamm .dropdown .dropdown-menu
{
  display: block;

  visibility: hidden;
  overflow: hidden;
  max-height: 0px;
  opacity: 0%;
  transition: opacity 0.3s;
}

header .topmenu .yamm .dropdown:hover>.dropdown-menu {
  visibility: visible;
  max-height: 800px;
  opacity: 100%;
  transition: opacity 0.3s;
}

header .topmenu .yamm .dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

header .topmenu .yamm .dropdown .dropdown-menu,
header .topmenu .yamm .dropup .dropdown-menu
{
  margin-top: -22px;
  //padding: 15px;
  //padding-top: 30px;
  box-shadow: 2px 2px 2px #444;
}

header .topmenu .yamm .dropdown.yamm-fw .dropdown-menu
{
  left: 20% !important;
  right: 20% !important;
}

header .topmenu .yamm .dropdown .dropdown-toggle::after
{
  display: none;
}

header .topmenu .yamm .yamm-content .media
{
  padding: 20px;
  height: 100%;
}

header .topmenu .yamm .yamm-content .media:hover
{
  background-color: #f5f5f5;
}

header .topmenu .yamm .yamm-content .media img
{
  width: 80px;
  padding: 8px;
  padding-right: 24px;
}

header .topmenu .yamm .yamm-content .media p
{
  margin-bottom: 0px;
}

//header .topmenu .yamm .dropdown .dropdown-menu.bang .col-6:nth-child(2)
//{
//  //background-color: #f3f9ff;
//  background-color: #efe9e3;
//  padding-bottom: 30px;
//}
//
//header .topmenu .yamm .dropdown .dropdown-menu.bang .col-6:nth-child(1) ul.nav
//{
//  align-items: flex-end;
//  height: 100%;
//  justify-content: center;
//}
//
//header .topmenu .yamm .dropdown .dropdown-menu.bang .col-6:nth-child(2) ul.nav
//{
//  align-items: flex-start;
//}
//
//header .topmenu .yamm .dropdown .dropdown-menu.bang ul.highlights
//{
//  list-style-type: none;
//  padding: 0px;
//  margin: 0px;
//  text-align: left;
//  padding-left: 10%;
//  padding-right: 10%;
//}
//
//header .topmenu .yamm .dropdown .dropdown-menu.bang ul.highlights > li,
//header .topmenu .yamm .dropdown .dropdown-menu.bang ul.highlights > li > a
//{
//  color: #09639f;
//}
//
//header .topmenu .yamm .dropdown .dropdown-menu.bang ul.highlights > li > img
//{
//  width: 48px;
//  padding-right: 6px;
//  filter: opacity(.5) drop-shadow(0 0 0 #00ffff);
//}



//.documentation-body header
//{
//  height: 190px;
//}

.subheader
{
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
}

div.main.subheader-spaced
{
  margin-top: 190px;
}

.subheader .subheader-holder {
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  z-index: 9;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px #eaeaea solid;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.subheader .subheader-holder .breadcrumbs
{
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  border-left: 1px #ccc solid;
}

.subheader .subheader-holder a
{
  color: #0d3349;
  //margin-left: 20px;
  //margin-right: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.subheader .subheader-holder a.active
{
  font-weight: 600;
  color: #0074ad;
}

.subheader .subheader-holder .btn-group a.btn
{
  margin-left: 0px;
  margin-right: 0px;
}

.subheader .subheader-holder .btn-group
{
  margin-left: 20px;
  margin-right: 20px;
}

.subheader .subheader-v1-holder {
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  z-index: 9;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px #eaeaea solid;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subheader .subheader-v1-holder a
{
  color: #0d3349;
  margin-left: 20px;
  margin-right: 20px;
}

.subheader .subheader-v1-holder a.active
{
  font-weight: 600;
  color: #0074ad;
}


/** ZOOM */
.zoom
{
  transition: transform .2s; /* Animation */
}

.zoom:hover
{
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.zoom-small
{
  transition: transform .2s; /* Animation */
}

.zoom-small:hover
{
  transform: scale(1.02);
}




.center
{
  text-align: center;
}

.section
{
  padding-top: 40px;
  padding-bottom: 40px;
}




/*Testimonial box and font style and colours*/
.testimonial {
  padding: 20px 20px;
  background-color: #f1f8fe;
  float: left;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  font-weight: 300;
  font-size: 1.3em;
  /*font-style:normal;*/
  /*color: #333;*/
  /*letter-spacing: 0.1px;*/
  text-align: left;

}
/*Author section wrapper*/
.author-wrapper {
  width: 100%;
  margin-bottom: 20px;
  float: left;
}
/*This creates the arrow with no need for an image, thanks to css-tricks.com*/
.testimonial-arrow {
  float: left;
  margin-left: 25px;
  width: 0;
  height: 0;
  border-top: 40px solid #f1f8fe; /*Change colour here to match .testimonial*/
  border-left: 30px solid transparent;
  border-right: 0px solid transparent; /*Make this 30px to create a triangle*/
}
/*Author Name*/
.testimonial-name {
  float: left;
  margin: 0 8px;
  color: #1e4673;
  font-weight: 400;
  padding-top: 20px;
  font-size: 1.1em;
}
.testimonial-name a
{
  font-weight:300;
}
.testimonial-link
{
  margin-top: 60px;
  text-align: center;
}
.testimonial-photo
{
  float: left;
  margin-top: 10px;
}

.testimonial-photo img.media-photo
{
  border: 1px #aaa solid;
  border-radius: 50%;
  width: auto !important;
  height: auto !important;
  max-width: 90px;
  max-height: 90px;
}




/** FOOTER **/

footer {
  background: #121214;
  border-top: 4px solid #1f1f1f;
  font-size: 1.1em;
  position: relative;
  padding-top: 30px;
}

div.footer-column ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}

div.footer-column ul li
{
  color: #aaa;
}

div.footer-column ul p
{
  margin-bottom: 10px;
}

div.footer-column ul a
{
  color: #eee !important;
  font-size: 16px;
  font-weight: 300;
}

div.footer-column h4
{
  color: #eee;
}

#sub-menu li a
{
  color: #ddd !important;
}

div.footer-copyright p
{
  color: #ddd;
}


.section h2
{
  font-size: 2.6em;
}









.content-section
{
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.content-section .row:first-child > .col
{
  padding-top: 30px;
  padding-bottom: 20px;
}

.content-section p
{
  padding-left: 0px;
  font-size: 18px;
}

.content-section ul,
.content-section ol
{
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-section ul li,
.content-section ol li
{
  font-size: 19px;
  color: #09639f;
}

.content-section ul li i.fa,
.content-section ol li i.fa
{
  padding-right: 8px;
}

.content-section ul li > p,
.content-section ol li > p
{
  //font-size: 0.8em;
  font-size: 17px;
  color: #777;
  padding-left: 30px;
  //margin-top: 8px;
  margin-top: 0px;
  margin-bottom: 10px;
}



.nav-item.spacing
{
  margin-left: 5px;
  margin-right: 5px;
}

.alpaca-example2 .card-header-tabs
{
  margin-bottom: -0.75rem;
}

.editors .editor
{
  text-align: left;
}
.editor-container
{
    width: 100%;
    height: 300px;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.btn-outline-primary
{
  color: scale-color($btn-outline-primary, $lightness: -40%) !important;
  border-color: $btn-outline-primary !important;
  border-top-color: scale-color($btn-outline-primary, $lightness: 20%) !important;
  border-left-color: scale-color($btn-outline-primary, $lightness: 20%) !important;
  border-bottom-color: scale-color($btn-outline-primary, $lightness: -20%) !important;
  border-right-color: scale-color($btn-outline-primary, $lightness: -20%) !important;
}

.btn-outline-primary:hover
{
  color: white !important;
  background-color: scale-color($btn-outline-primary, $lightness: -30%) !important;
}

.rounder
{
  border-radius: 6px;
  font-weight: 500;
  font-size: 1.15em;
  //box-shadow: #ccc 2px 2px 5px;
}

.content-section a,
.buttons a,
.biggies a
{
  color: scale-color($btn-outline-primary, $lightness: -15%);
  //scolor: #298dbf;
  font-weight: 600;
}

$backact1-background-color: $pal !default;
.backact1
{
  background-color: $backact1-background-color !important;
  color: white !important;
  border-color: scale-color($backact1-background-color, $lightness: -15%);
}

a.backact1:hover
{
  background-color: scale-color($backact1-background-color, $lightness: -15%) !important;
  color: white !important;
  border-color: scale-color($backact1-background-color, $lightness: -15%);

}



$backact2-background-color: #48cae4 !default;
$backact2-background-color-hover: scale-color($backact2-background-color, $lightness: 20%) !default;
$backact2-border-color: scale-color($backact2-background-color, $lightness: -15%) !important;
//$backact2-text-color-low: scale-color($backact2-background-color, $lightness: -75%) !default;
//$backact2-text-color-high: scale-color($backact2-background-color, $lightness: 75%) !default;
$backact2-text-color-low: white !default;
$backact2-text-color-high: white !default;

.backact2,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact2
{
  background-color: $backact2-background-color !important;
  color: determine-text-color($backact2-background-color, $backact2-text-color-low, $backact2-text-color-high) !important;
  //color: $backact2-text-color !important;;
  border-color: $backact2-border-color !important;

  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-weight: 600 !important;
  box-shadow: 1px 1px #333 !important;
  border-left: 1px scale-color($backact2-background-color, $lightness: 20%) solid !important;
  border-top: 1px scale-color($backact2-background-color, $lightness: 20%) solid !important;
  border-right: 1px scale-color($backact2-background-color, $lightness: -15%) solid !important;
  border-bottom: 1px scale-color($backact2-background-color, $lightness: -15%) solid !important;
  border-radius: 8px !important;
}
a.backact2:hover,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact2:hover
{
  background-color: $backact2-background-color-hover !important;
  color: $backact2-text-color-high !important;
  //color: $backact2-text-color !important;;
}


$backact3-background-color: #FFC107 !default;
$backact3-background-color-hover: scale-color($backact3-background-color, $lightness: 20%) !default;
$backact3-border-color: scale-color($backact3-background-color, $lightness: -15%) !important;
$backact3-text-color-low: #6D4C41 !default;
$backact3-text-color-high: #6D4C41 !default;

.backact3,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact3
{
  background-color: $backact3-background-color !important;
  color: determine-text-color($backact3-background-color, $backact3-text-color-low, $backact3-text-color-high) !important;
  border-color: $backact3-border-color !important;

  //padding: 10px !important;
  //padding-left: 20px !important;
  //padding-right: 20px !important;
  font-weight: 600 !important;
  //box-shadow: 1px 1px #888 !important;
  border-left: 1px scale-color($backact3-background-color, $lightness: 20%) solid !important;
  border-top: 1px scale-color($backact3-background-color, $lightness: 20%) solid !important;
  border-right: 1px scale-color($backact3-background-color, $lightness: -15%) solid !important;
  border-bottom: 1px scale-color($backact3-background-color, $lightness: -15%) solid !important;
  border-radius: 8px !important;
}
a.backact3:hover,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact3:hover
{
  background-color: $backact3-background-color-hover !important;
  color: $backact3-text-color-high !important;
}

//$backact4-background-color: #2196F3 !default;
//$backact4-background-color-hover: scale-color($backact4-background-color, $lightness: 10%) !default;
//$backact4-border-color: scale-color($backact4-background-color, $lightness: -15%) !important;
//$backact4-text-color-low: white !default;
//$backact4-text-color-high: white !default;

//$backact4-background-color: #2196F3 !default;
$backact4-background-color: #0176d3 !default;
$backact4-background-color-hover: scale-color($backact4-background-color, $lightness: 10%) !default;
$backact4-border-color: scale-color($backact4-background-color, $lightness: -15%) !important;
$backact4-text-color-low: white !default;
$backact4-text-color-high: white !default;

.backact4,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact4
{
  background-color: $backact4-background-color !important;
  color: determine-text-color($backact4-background-color, $backact4-text-color-low, $backact4-text-color-high) !important;
  border-color: $backact4-border-color !important;

  border-left: 1px scale-color($backact4-background-color, $lightness: 20%) solid !important;
  border-top: 1px scale-color($backact4-background-color, $lightness: 20%) solid !important;
  border-right: 1px scale-color($backact4-background-color, $lightness: -15%) solid !important;
  border-bottom: 1px scale-color($backact4-background-color, $lightness: -15%) solid !important;
  border-radius: 3px !important;

  font-weight: 400 !important;
  font-size: 19px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  //transition: .25s ease-in-out;
}
a.backact4:hover,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact4:hover
{
  background-color: $backact4-background-color-hover !important;
  color: $backact4-text-color-high !important;
  transform:scale(1.03, 1.03);
  -webkit-transform:scale(1.03, 1.03);
  -moz-transform:scale(1.03, 1.03);
}

$backact6-background-color: #fff !default;
$backact6-background-color-hover: scale-color($backact6-background-color, $lightness: -10%) !default;
$backact6-border-color: scale-color($backact6-background-color, $lightness: -15%) !important;
$backact6-text-color-low: #101010 !default;
$backact6-text-color-high: #101010 !default;

.backact6,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact6
{
  background-color: $backact6-background-color !important;
  color: determine-text-color($backact6-background-color, $backact6-text-color-low, $backact6-text-color-high) !important;
  border-color: $backact6-border-color !important;

  border-left: 1px scale-color($backact6-background-color, $lightness: 20%) solid !important;
  border-top: 1px scale-color($backact6-background-color, $lightness: 20%) solid !important;
  border-right: 1px scale-color($backact6-background-color, $lightness: -15%) solid !important;
  border-bottom: 1px scale-color($backact6-background-color, $lightness: -15%) solid !important;
  border-radius: 3px !important;

  font-weight: 400 !important;
  font-size: 19px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  //transition: .25s ease-in-out;
}
a.backact6:hover,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact6:hover
{
  background-color: $backact6-background-color-hover !important;
  color: $backact6-text-color-high !important;
  transform:scale(1.03, 1.03);
  -webkit-transform:scale(1.03, 1.03);
  -moz-transform:scale(1.03, 1.03);
}

$backact5-background-color: #2196F3 !default;
$backact5-background-color-hover: scale-color($backact5-background-color, $lightness: 10%) !default;
$backact5-border-color: scale-color($backact5-background-color, $lightness: -15%) !important;
$backact5-text-color-low: white !default;
$backact5-text-color-high: white !default;

.backact5,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact5
{
  background-color: $backact5-background-color !important;
  color: determine-text-color($backact5-background-color, $backact5-text-color-low, $backact5-text-color-high) !important;
  border-color: $backact5-border-color !important;

  border-left: 1px scale-color($backact5-background-color, $lightness: 20%) solid !important;
  border-top: 1px scale-color($backact5-background-color, $lightness: 20%) solid !important;
  border-right: 1px scale-color($backact5-background-color, $lightness: -15%) solid !important;
  border-bottom: 1px scale-color($backact5-background-color, $lightness: -15%) solid !important;
  border-radius: 3px !important;

  font-weight: 400 !important;
  //transition: .25s ease-in-out;
  font-size: 17px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  //transition: .25s ease-in-out;
}
a.backact5:hover,
.main section.topper .container-fluid > .row.content-row a.btn-outline-primary.rounder.backact5:hover
{
  background-color: $backact5-background-color-hover !important;
  color: $backact5-text-color-high !important;
  transform:scale(1.03, 1.03);
  -webkit-transform:scale(1.03, 1.03);
  -moz-transform:scale(1.03, 1.03);
}




.linkact1
{
  //color: #d46901 !important;
  //border: 0px !important;
  color: scale-color($btn-outline-primary, $lightness: -15%);
  font-weight: 600 !important;
}

.backwhite
{
  background-color: $white !important;
}

.people .media-object.media-photo
{
  max-width: 200px;
}

body
{
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
}

div.main
{
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-top: 140px;
}

.sided
{
  padding-left: 10%;
  padding-right: 10%;
}

.bio
{
}

.bio .bio-metadata-holder
{
  text-align: center;
}

.bio .bio-metadata-holder img.bio-photo
{
  border-radius: 16px;
  max-width: 250px;
  height: 200px;
  //filter: sepia(0.5);
}

//.bio .bio-metadata-holder img.bio-photo:hover
//{
//  filter: grayscale(0);
//}

.bio .bio-metadata-holder h4.bio-heading
{
  font-weight: 300;
  font-size: 1.3em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bio .bio-metadata-holder h5.bio-subheading
{
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bio .bio-photo-holder .bio-photo
{
  max-width: 200px;
}

.bio-body
{
  font-weight: 300;
  font-size: 1.0em;
  margin-top: 10px;
  margin-bottom: 10px;

}

.faq
{
}

.faq .col-6
{
  margin-bottom: 10px;
}

.faq .faq-question
{
  font-size: 1.1em;
}

.faq .faq-answer
{
  font-weight: 400;
  font-size: 1.0em;
  color: #555;
}

.faq .faq-header
{
  margin-top: 30px;
  margin-bottom: 50px;
}


.screenshotx
{
  width: 100%;
  border-right: 1px #333 solid;
  border-bottom: 1px #333 solid;
  border-left: 1px #333 solid;
  border-top: 1px #333 solid;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #0a6aa1;
  align-content: center;
}

.screenshotx1
{
  //width: 100%;
}

.screenshotx2
{
  width: 100%;
  align-content: center;
  filter: drop-shadow(3px 3px 10px #0a6aa1);
}

.screenshotx3
{
  width: 100%;
  align-content: center;
  filter: drop-shadow(3px 3px 3px #0a6aa1);
}

img.hinty
{
  margin-top: 10px;
  align-content: center;
  border-radius: 50%;
  width: 65%;
  filter: blur(1px);
}

//.topper.usecase,
//.topper.features,
//.topper.solutions
//{
//  margin-top:20px;
//}

ul.bong li:hover
{
  background-color: #fff9f3;
  font-weight: 600;

}

.media.previewbox img
{
  width: 50px;
  //max-height: 75px;
}

p.shortsum
{
  //color: #e47911 !important;
  //color: #d46901 !important;
  //color: #cd6133 !important;
  color: #FFED6D !important;
  //font-size: 1.25em !important;
  font-size: $p_size_1 !important;
  font-weight: 400 !important;
}

p.shortsum.white
{
  color: white !important;
}

p.shortsum em
{
  //text-decoration: underline;
  font-weight: 600;
}

img.bicon
{
  width: 32px;
  filter: opacity(.6) drop-shadow(0 0 0 #0ff);
  padding-right: 6px;
}

.card.doc_card
{
  //border: 0px;
  margin-bottom: 30px;
}

h2.short.word-rotator-title,
h3
{
  color: $gray-700;
}

ul.headspaced
{
  margin-top: 20px;
}
ul.headspaced > li
{
  margin-top: 10px;
}
ul.headspaced > li a
{
  color: #09639f !important;
}
ul.headspaced > li i
{
  color: #777 !important;
  margin-right: 6px !important;
}


.search-result-header a
{
  color: #236f95;
  //font-weight: 600;
}

[data-menu-href]
{
  cursor: pointer;
}

.gitana-logo
{
  //margin-left: 50px;
  //margin-right: 50px;
  padding-left: 20px;
}

.gitana-logo img
{
  //width: 34px !important;
  //height: 40px !important;

  //margin: 0px !important;
  //filter: hue-rotate(180deg) drop-shadow(1px 1px 1px black);
  //filter: drop-shadow(1px 1px 1px #777);
  //filter: hue-rotate(180deg) grayscale(75%) drop-shadow(1px 1px 1px black);
  //margin-top: -8px;

  height: 90px !important;
  margin: 0 !important;
  filter: drop-shadow(1px 1px 0px #999);
}

//.gitana-logo span.gitana-logo-label
//{
//  font-family: Avenir !important;
//  color: #333 !important;
//  font-weight: 500;
//  font-size: 26px;
//  text-shadow: -1px -1px #eee !important;
//  margin-left: 2px;
//  vertical-align: middle;
//  letter-spacing: 3px;
//  text-rendering: optimizeLegibility;
//}

.gitana-logo span.gitana-logo-sub
{
  font-family: Avenir !important;
  color: #777 !important;
  font-weight: 200;
  font-size: 11px;
  text-shadow: none !important;
  //text-transform: uppercase;
  position: relative;
  left: -102px;
  top: 20px;
  letter-spacing: 2px;
}

.keypoints
{
  width: 100%;
  height: 100%;
}
.keypoints ul li span.keypoints-header
{
  cursor: pointer !important;
}

.c1
{
  text-align: center;
}

.c1
{
  margin-bottom: 40px;
}

.button-sp-top
{
  margin-top: 30px;
}

figure > figcaption
{
  margin-top:20px;
  font-weight: bold;
}

section.topper .topper-left p em
{
  color: $yellowback-color;
  //font-weight: 400;
}

.btn.rounder:not(:first-child)
{
  margin-left: 20px;
}

.media.media-item1
{
  margin-bottom: 20px;
}

.media.media-item1 img
{
  width: 64px;
}

.media .media-body p
{
  padding-left: 0px !important;
  color: #777;
  font-size: 17px;
}

.media.media-item1 h5
{
  color: #039be5 !important;
}

.key-features-block.key-features-block2
{
  height: unset;
}

.consulting-option
{
  padding-bottom: 20px;
}

.consulting-option h4
{
  color: #039be5;
}

.bigup1
{

}

.bigup1 .image-holder
{
  text-align: center;
  margin: 15px;
}

.bigup1 h5
{
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.bigup1 img
{
  //width: 100%;
  //width: -webkit-fill-available;
  margin: 10px;
}

.bigup1 .image-holder.tih1 img
{
  width: 100%;
}

.bigup1 p
{
  font-size: 17px;
  color: #777;
  padding-left: 0px;
  padding-right: 0px;
}

.bigup1 ul
{
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bigup1 ul li
{
  font-size: 17px;
  font-weight: 600;
}
.bigup1 ul li p
{
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 400;
}




.api-function
{

}

.api-function .api-function-invocation
{

}

.api-function .api-function-description
{

}

.api-function .api-function-parameters
{

}

.api-function .api-function-example
{

}

.api-function .api-function-invocation,
.api-function .api-function-example .request,
.api-function .api-function-example .response
{

  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
  font-size: inherit;
  color: inherit;
  word-break: normal;
  color: #81a;
  font-weight: 600;
  background: #eaeaea;
  padding: 4px;
  color: #81a;
  font-weight: 400;
  background: unset;
  padding: unset;
}

.api-function .api-function-invocation
{
  margin-bottom: 20px;
}

.api-function .api-function-example .request,
.api-function .api-function-example .response
{
  padding: 20px;
  border: 1px #ddd solid;
  border-radius: 6px;
  margin-top: 20px;
  background-color: #fafafa;
}

.api-function .api-function-example .request h3,
.api-function .api-function-example .response h3
{
  margin-top: 0px;
  font-size: 20px;
}

.api-function .api-function-example .request .request-inner,
.api-function .api-function-example .response .response-inner
{
  font-size: 14px;
}

ul.pagination li a
{
  margin-left: 10px;
}

.social-icons
{
  display: flex;
  flex: 1;
}

.social-icons .social-icon
{
  font-size:32px;
  padding: 4px;
}

.social-icons .social-icon a
{
  color: #ccc;
}

.social-icons .social-icon a i
{

}

.gitana-conversion-header-logo
{
  text-align: center;
}

.gitana-conversion-header-logo img
{
  height: 140px !important;
  margin: 0px !important;
  margin-top: 4px !important;
  filter: drop-shadow(1px 1px 0px #000);
}

header .container-fluid.topbar > nav.navbar
{
  justify-content: flex-end;
}

.btn.disable1:disabled,
.btn.disable1:disabled:hover
{
  background-color: unset !important;
  color: unset !important;
  opacity: 0.25 !important;
}

.newsletter-holder
{
  margin-bottom: 20px;
}

.newsletter-holder h4
{
  color: #eee;
  margin-bottom: 0px !important;
}

.newsletter-holder p
{
  color: #aaa;
  font-size: 16px;
}

.newsletter-holder .newsletter-button-holder
{
  display: inline-flex;
}

.newsletter-holder .newsletter-button-holder input:first-child
{
  margin-right: 5px;
}

.padder1
{
  padding-left: 150px !important;
  padding-right: 150px !important;
}