div.topbar nav.navbar
{
  border-top: 0px;
  margin-left: -15px;
  margin-right: -15px;

  padding: 0px;
  padding-left: 15px;
  padding-right: 15px;

  background-image: none;
  background-color: $topbar-background-color !important;
  //background: $topbar-background;
  color: $topbar-text-color;
}

div.topbar nav.navbar a,
div.topbar nav.navbar a:hover,
div.topbar nav.navbar a:visited
{
  color: $topbar-text-color;
  text-decoration: none;
}

div.topbar nav.navbar .navbar-text,
div.topbar nav.navbar .navbar-text a
{
  color: $topbar-text-color;
}

/*
div.topbar nav.navbar .announcement-action.buynow a
{
  color: orange !important;
}
*/
